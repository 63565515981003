import { Component } from "@angular/core";

/**
 * A Wrapper component for the spinner css and html.
 */
@Component({
    selector: "esr-spinner",
    templateUrl: "./spinner.component.html",
    styleUrls: ["./spinner.component.scss"],
    standalone: true
})
export class SpinnerComponent {}
