
/** The announcement model */
export interface Announcement {
  /**
   * Object id
   */
  id?: number;
  /**
   * Description of the announcement
   */
  description: string;
  /**
   * Title of the announcement
   */
  title: string;
  /**
   * The day from which the announcment is visible on the site
   */
  enabled_from: Date;
  /**
   * The day from which the announcment is not visible on the site anymore
   */
  enabled_until: Date;
    /**
   * Defines if the announcement is a roeiverbod (0), warning (1) or announcement (2)
   */
  level: number;
}
