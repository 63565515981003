import { Injectable } from "@angular/core";

import { NewsState, NewsStore } from "./news.store";
import { transaction } from "@datorama/akita";
import { ApiResponse } from "../../shared-resources/api-response";
import { NgEntityService } from "@datorama/akita-ng-entity-service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

/**
 * NewsService extends the NgEntityService from Akita.
 * By extending NgEntityService, we get a several built-in
 * API calls without the need to add them ourselves: get(), add(), update() and delete().
 * https://datorama.github.io/akita/docs/angular/entity-service/
 */
@Injectable({ providedIn: "root" })
export class NewsService extends NgEntityService<NewsState> {
  /** @ignore */
  constructor(protected store: NewsStore, private httpservice: HttpClient) {
    super(store);
  }

  /**
   * Transactions are an optimization for performing multiple operations on the store.
   * See Akita documentation: https://datorama.github.io/akita/docs/transactions/
   * @param page The current page number
   * @param res The response from the backend
   */
  @transaction()
  updateState(page: number, res: ApiResponse) {
    const nextPage = page + 1;
    const hasMore = res.next ? true : false;
    this.store.add(res.results);
    this.store.updateState({ hasMore, nextPage });
    this.store.setLoading(false);
  }

  postcomment(commenttext: string, id: number) {
    return this.httpservice.post(`${environment.apiBaseUri}/news/${id}/comment/`, {
      text: commenttext,
    });
  }

  deleteComment(commentId: number) {
    return this.httpservice.delete(`${environment.apiBaseUri}/news/comment/${commentId}`);
  }
}
