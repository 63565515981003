import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NavigationStore } from './navigation.store';

@Injectable({ providedIn: "root" })
export class NavigationService {

  constructor(private navigationStore: NavigationStore, private http: HttpClient) {
  }

  getUnreadMessages() {
    const pageUriSpams = [environment.apiBaseUri, "spams/unread"].join("/")
    const pageUriFrusts = [environment.apiBaseUri, "frusts/unread"].join("/")
    const pageUriAlbums = [environment.apiBaseUri, "photoalbums/unseen"].join("/")
    this.http.get(pageUriSpams).subscribe((data) => this.navigationStore.update({ unreadSpams: data as number }));
    this.http.get(pageUriFrusts).subscribe((data) => this.navigationStore.update({ unreadFrusts: data as number }));
    this.http.get(pageUriAlbums).subscribe((data) => this.navigationStore.update({ unreadAlbums: data as number }));
  }

}

