import { User } from "src/app/login-module/auth/state/user.model";

export interface Comment {
  /** The id of the comment */
  id: number;
  /** The comment's author */
  author: User;
  /** The comment's text */
  text: string;
  /** The creation date */
  pub_date: string;
}
