<nav
  class="navbar"
  role="navigation"
  aria-label="main navigation"
  (window:resize)="onResize($event)"
>
  <div *ngIf="!lustrum" class="navbar-brand">
    <a *ngIf = "isLoggedIn$" class="navbar-item" routerLink="/news"></a>
    <a *ngIf = "isLoggedIn$ === false" class="navbar-item" routerLink="/"></a>
    <div
      id="navbarBurger"
      role="button"
      class="navbar-burger"
      (click)="toggleMobileMenu()"
      aria-label="menu"
      aria-expanded="false"
      data-target="navMenu"
    >
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </div>
    <span *ngIf="unreadIndicator$ | async" class="is-hidden-desktop badge is-primary navbar-badge"></span>
  </div>

  <div *ngIf="lustrum" class="navbar-lustrum">
    <a *ngIf = "isLoggedIn$" class="navbar-item" routerLink="/news"></a>
    <a *ngIf = "isLoggedIn$ === false" class="navbar-item" routerLink="/"></a>
    <div
      id="navbarBurger"
      role="button"
      class="navbar-burger"
      (click)="toggleMobileMenu()"
      aria-label="menu"
      aria-expanded="false"
      data-target="navMenu"
    >
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </div>
    <span *ngIf="unreadIndicator$ | async" class="is-hidden-desktop badge navbar-badge" style="background:#9b1b1f"></span>
  </div>

  <!-- Public desktop navbar // Public and Member mobile navbar -->
  <div id="navMenu" class="navbar-menu py-0">
    <!-- Desktop View -->
    <ng-container>
      <div *ngIf="!isMobile" class="navbar-end is-hidden-touch">

        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link is-arrowless" routerLink="/publiek/lustrum">
            Lustrum
          </a>
          <div class="navbar-dropdown">
            <div>
              <a class="navbar-item" routerLink="/publiek/lustrum/Xplosie">
                Xplosie
              </a>
              <h2></h2>
            </div>
            <div *ngIf="isLoggedIn$">
              <a class="navbar-item" routerLink="/publiek/lustrum/pakketten">
                Lustrumpakketten
              </a>
              <h2></h2>
            </div>
          </div>
          <h2></h2>
        </div>

        <ng-container *ngFor="let page of publicPageSlugs" >
          <!-- Navbar item has sub pages -->
          <div class="navbar-item has-dropdown is-hoverable" *ngIf="hasSubPages(page); else noSubpages">
            <a class="navbar-link is-arrowless" routerLink="/publiek/{{ page }}">
              {{ getPageName(page) }}
            </a>
            <div class="navbar-dropdown">
              <div *ngFor="let subPage of getSubPages(page)">
                <a class="navbar-item" routerLink="/publiek/{{ page }}/{{ subPage }}">
                  {{ getPageName(subPage, page) }}
                </a>
                <h2 *ngIf="page === 'lustrum'"></h2>
                <h2 *ngIf="lustrum"></h2>
              </div>
            </div>
            <h2 *ngIf="page === 'lustrum'"></h2>
            <h2 *ngIf="lustrum"></h2>
          </div>

          <!-- Navbar item has no sub pages -->
          <ng-template #noSubpages>
            <a class="navbar-item" routerLink="/publiek/{{ page }}">
              <ng-container *ngIf="unreadAlbums$ | async as unreadAlbums">
                <ng-container *ngIf="lustrum">
                  <span *ngIf="getPageName(page) === 'Fotoalbums' && unreadAlbums > 0" title="Badge" class="badge is-top-right"
                    style='box-shadow: none;transform: translate(-10%, 10%);color:#fff4e2;background-color:#9b1b1f;'>{{unreadAlbums}}</span>
                </ng-container>
                <ng-container *ngIf="!lustrum">
                  <span *ngIf="getPageName(page) === 'Fotoalbums' && unreadAlbums > 0" title="Badge" class="badge is-top-right is-primary"
                    style='box-shadow: none;transform: translate(-10%, 10%);color:black;'>{{unreadAlbums}}</span>
                </ng-container>
              </ng-container>
              {{ getPageName(page) }}
            </a>
          </ng-template>

        </ng-container>

        <!-- Not included in PageSlugs -->
        <div class="navbar-item is-hoverable">
          <a class="navbar-link is-arrowless" routerLink="/publiek/zakelijk/vacatures/">Vacatures</a>
        </div>
        <a class="navbar-link is-arrowless has-text-info" href="mailto:incoming+esrtheta-web3-5-frontend-12458795-issue-@incoming.gitlab.com?body=Alsjeblieft aangeven wat er mis ging en wat jij had verwacht dat zou gebeuren. Screenshots zijn een grote hulp! Dankjewel voor de feedback!">Feedback</a>
        <a class="navbar-link is-arrowless has-text-info" routerLink="/login" *ngIf="!isLoggedIn$">Login</a>
      </div>
    </ng-container>

    

    <!-- Mobile Navbar -->
    <esr-mobile-navbar *ngIf="isMobile"></esr-mobile-navbar>

  </div>
</nav>

<esr-member-navbar *ngIf="!isMobile"></esr-member-navbar>
