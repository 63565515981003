import { Component } from "@angular/core";
import { UserSettingsQuery } from "src/app/user-settings/query";
import { FooterComponent  } from "../../footer/footer.component";
import { Router, RouterOutlet } from "@angular/router";
import { NavigationComponent } from "../../navigation/navigation.component";
import { HeroImageBannerComponent } from "../../hero-image-banner/hero-image-banner.component";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";


/**
 * The `esr-website` component is the main entrypoint for the E.S.R. Thêta
 * website. It is responsible for managing the layout of the:
 *
 * * Main website content.
 * * [`esr-footer`]{@link FooterComponent}
 * * [`esr-hero-image-banner`]{@link HeroImageBannerComponent}
 * * [`esr-navigation`]{@link NavigationComponent}
 * * [`esr-sidebar`]{@link SidebarComponent}
 *
 * In case a user is logged in, this component is responsible for passing the
 * appropriate information into components that require it (e.g.
 * [`esr-navigation`]{@link NavigationComponent}, [`esr-sidebar`]{@link
 * SidebarComponent}).
 *
 * Some of the laid out components may be hidden using parameters defined by
 * the routes used to route to the main website contents. For instance, the
 * [`esr-hero-image-banner`]{@link HeroImageBannerComponent} or the
 * [`esr-sidebar`]{@link SidebarComponent} should not be shown for the photo
 * album.
 */
@Component({
    selector: "esr-website",
    styleUrls: ["./website.component.scss"],
    templateUrl: "./website.component.html",
    standalone: true,
    imports: [HeroImageBannerComponent, NavigationComponent, RouterOutlet, FooterComponent]
})
export class WebsiteComponent {

  favIcon: HTMLLinkElement = document.querySelector('#appIcon')!;


  /** @ignore */
  constructor(
    private userSettingsQuery: UserSettingsQuery,
    private library: FaIconLibrary,
    private router: Router
    ) {

      

      router.events.subscribe(val => {
        if (this.router.url.includes("lustrum")) {
          this.favIcon.href = 'assets/pwa-icons/Site icon vlam PNG.png';
        } else {
          this.favIcon.href = 'assets/pwa-icons/favicon-196.png';
        }
      })

    library.addIconPacks(fas, fab)

    // geef een passende welkomstmelding aan bezoekers wanneer ze de console openen
    console.log(
      "%c ╭─╮                         ╭─╮ \n╰┼─┼╮  'vo voor de WWWCie   ╰┼─┼╮\n ╰─╯                         ╰─╯ \n      Thêta wint de varsity!     ",
      "font-family:Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New, monospace; font-size:45px; color:#412107; background-color:#ffdd00;"
    );

    console.log("%c Wil je bijdragen aan de website? mailto://wwwcie@esrtheta.nl ",
    "font-family:Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New, monospace; font-size:24px; color:#412107; background-color:#ffdd00;"
    )

    // Stel het passende thema in voor de bezoeker
    this.applyDarkMode(); 
    // en pas het aan als het browser-thema veranderd
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
        //const newColorScheme = event.matches ? "dark" : "light";
        // Stel het passende thema in voor de bezoeker
        this.applyDarkMode(); 
    });
    
  }

  applyDarkMode() {
    if(this.userSettingsQuery.getValue().userChangedDarkMode){
      this.userSettingsQuery.getValue().userWantsDarkMode ?
        document.documentElement.classList.add("dark-mode") :
        document.documentElement.classList.remove("dark-mode");
      
    } else {
      window.matchMedia("(prefers-color-scheme: dark)").matches ?
        document.documentElement.classList.add("dark-mode") :
        document.documentElement.classList.remove("dark-mode");
    }
  }

}
