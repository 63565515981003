import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Poll } from './poll.model';

export interface PollState extends EntityState<Poll> { }

@StoreConfig({
  name: 'polls'
})
@Injectable({ providedIn: "root" })
export class PollStore extends EntityStore<PollState> {

  constructor() {
    super();
  }

  /**
   * Given a new state number updates the store's state.
   * @param state The new state
   */
  updateState(state: { hasMore: boolean; nextPage: number }) {
    this.update(state);
  }

}

