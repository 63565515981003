import { Injectable } from "@angular/core";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

import { Sponsor } from "./sponsor";

/**
 * We need to define the store's interface by extending the EntityState from Akita
 */
export interface SponsorState extends EntityState<Sponsor> {
    /** Boolean, used to check if there are more pages to load */
    hasMore: boolean;
    /** Number, used to keep track of the next page number */
    nextPage: number;
    
}

const initialState: SponsorState = {
  hasMore: true,
  nextPage: 1,
};

/**
 * The Store is a single object which contains the store state and serves as the "single source of truth."
 * For the most part, the stores you'll require in your applications will be entity stores.
 * You can think of an entity store as a table in a database,
 * where each table represents a flat collection of entities.
 */
@Injectable({ providedIn: "root" })
@StoreConfig({ name: "sponsor" })
export class SponsorStore extends EntityStore<SponsorState> {
  /** @ignore */
  constructor() {
    super(initialState);
  }

    /**
   * Given a new state number updates the store's state.
   * @param state The new state
   */
    updateState(state: { hasMore: boolean; nextPage: number }) {
      this.update(state);
    }
}
