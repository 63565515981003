<div class="block" *ngFor="let item of newsItems$ | async">
  <esr-content class="news-item" [footer]="item.id.toString()" image="{{ item.photo_url }}">

      <!-- Title and Pins -->
      <div class="columns is-mobile">
        <div class="column is-9">
            <div class="title"><a class="has-text-dark" [routerLink]="item.id.toString()">{{ item.title }}</a></div>
            <div class="subtitle">{{ item.subtitle }}</div>
        </div>
        <div class="column is-3">
          <span *ngIf="item.on_top" class="is-size-4 is-pulled-right">
            <fa-icon id="pinned" [icon]="['fas','bookmark']"></fa-icon>
          </span>
          <span *ngIf="item.boardmessage" class="mr-2 is-size-4  is-pulled-right">
            <fa-icon id="boardMessage" [icon]="['fas','bell']"></fa-icon>
          </span>
          <div *ngIf="item.comments">
            <span id="comments" *ngIf="item.comments.length > 0" class="mr-2 is-size-5  is-pulled-right">
              {{item.comments.length}} <fa-icon [icon]="['fas','comment-alt']"></fa-icon>
            </span>
          </div>
        </div>
      </div>

      <!-- Contents -->
      <markdown class="content" [data]="item.content | truncate: 50"></markdown>
  </esr-content>
</div>

<!-- Footer -->
<footer class="card-footer" *ngIf="hasNextPage">
  <div class="card-footer-item">
    <esr-spinner *ngIf="loaders.get$ | async; else idle"></esr-spinner>
  </div>
  <ng-template #idle>
    <a id="next-page-link" class="card-footer-item" (click)="getNextPage()">Meer</a>
  </ng-template>
</footer>
