<div class="block">
  <esr-content>
    <h1 class="title">Fotoalbums</h1>
    <!-- this container only exists to subscribe to unreadAlbums$ in the component,
         if this would be subscribed in the component it keeps updating in the background -->
    <ng-container *ngIf="unreadAlbums$ | async as unreadAlbums"></ng-container>

    <form [formGroup]="searchForm" class="field has-addons">
      <div class="control is-expanded">
        <input class="input" type="string" formControlName="searchstring" placeholder="Titel" />
      </div>
      <div class="control">
        <input class="input" type="string" formControlName="photographer" placeholder="Fotograaf" />
      </div>
      <div class="control">
        <input type="number" class="input" formControlName="year" placeholder="Jaartal" />
      </div>
      <div class="control">
        <button type="button" class="button" (click)="searchForm.reset()">
          <fa-icon [icon]="['fas', 'times']"></fa-icon>
        </button>
      </div>
    </form>

  </esr-content>


  <div class=" mt-3 columns is-multiline is-mobile">
    <div class="column is-one-quarter-desktop is-one-third-tablet is-half-mobile"
      *ngFor="let item of photoAlbums$ | async">

      <a [routerLink]="item.id?.toString()">
        <div class="box p-0 photo-album"
          [ngClass]="[((unreadAlbumIDs$ | async)?.indexOf(item.id ?? -1) ?? -1) > -1? 'unread' : '']">
          <figure *ngIf="item.photos && item.photos[0]" class="image">
            <img style="border-top-left-radius: 0.25rem; border-top-right-radius: 0.25rem; object-fit: cover; object-position: center center;"
              alt="Card Image" src="{{ (item.photos && item.photos[0]) ? baseUrl + item.photos[0].thumb : '' }}" />
          </figure>
          <div class="card-content">
            <h4 class="title is-4">{{ item.title }}</h4>
            <h6 class="subtitle is-6">{{ item.photographer }}</h6>
            <time>{{
              item.album_date | date: "fullDate"
              }}</time>
          </div>
        </div>
      </a>
    </div>
  </div>
  <footer class="card-footer" *ngIf="hasNextPage$ | async">
    <div class="card-footer-item">
      <esr-spinner *ngIf="loaders.get$ | async; else idle"></esr-spinner>
    </div>
    <ng-template #idle>
      <a class="card-footer-item" (click)="getNextPage()">Meer</a>
    </ng-template>
  </footer>