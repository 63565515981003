<div *ngIf="photoAlbum$ | async as photoAlbum">
  <div class="block">
    <esr-content>
      <div class="columns">
        <div class="column is-half">
          <p class="title">
            {{photoAlbum.title}}
          </p>
        </div>
        <div class="column is-half has-text-right-tablet">
          <div class="title">
            {{photoAlbum.photographer}}
          </div>
          <p class="subtitle">{{
            photoAlbum.album_date | date: "longDate"
          }}</p>
        </div>
      </div>
    </esr-content>
  </div>
  <div *ngIf="photoAlbum$ | async as photoAlbum" class="columns is-multiline is-mobile">
    <div class="column is-one-fifth-desktop is-one-quarter-tablet is-one-third-mobile" *ngFor="let photo of photoAlbum.photos; let i=index">
      <div class="block">
        <figure class="image is-square">
          <a href="{{baseUrl}}{{photo.url}}" class="glightbox" data-gallery="gallery">
            <img src="{{baseUrl}}{{photo.thumb}}" />
          </a>
        </figure>
      </div>
    </div>
  </div>
</div>
