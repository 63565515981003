import { Component, Input, EventEmitter, Output } from "@angular/core";
import { NgClass, NgIf } from "@angular/common";
import { Router } from "@angular/router";

/** The modal component, used for displaying information in a pop up modal. */
@Component({
    selector: "esr-modal",
    templateUrl: "./modal.component.html",
    styleUrls: ["./modal.component.scss"],
    standalone: true,
    imports: [NgClass,NgIf]
})
export class ModalComponent {

  /** Title, used for diplaying the modal's titel in the html. */
  @Input() title: string = "";
  // string of the submit/success button
  @Input() submitString: string = "";
  // function to open the modal
  @Input()
    openModal() {
      this.isActive = true;
    }
  // output emitter when submitbutton is clicked
  @Output() submitModal:EventEmitter<void> = new EventEmitter();
  
  isActive: boolean = false;
  public lustrum = false;
  // called by the submit button. Forwards to the onSubmit emitter and closes modal
  public onSuccess(){
    this.submitModal.emit();
    this.closeModal();
  }

  /**
   * Removes the is-active attribute from the class to close the modal.
   */
  public closeModal() {
    this.isActive = false;
  }

  constructor(
    private router: Router
  ) {
    router.events.subscribe(val => {
      if (this.router.url.includes("lustrum")) {
        this.lustrum = true;
      } else {
        this.lustrum = false;
      }
    })
  }
}
