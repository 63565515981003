import { Component, OnInit } from "@angular/core";
import { AuthQuery } from "../login-module/auth/state";
import { Sponsor } from "./state/sponsor";
import { SponsorQuery } from "./state/query";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { SponsorService } from "./state/service";
import { ApiResponse } from "../shared-resources/api-response";
import { NgFor, NgClass, AsyncPipe } from "@angular/common";

/**
 * Display the sponsors of the ESR
 * Display sponsor tiers below each other
 * Display one sponsor per tier, random at load
 * Loop through tier sponsors per tier
 */
@Component({
    selector: "esr-sponsor",
    templateUrl: "./sponsor.component.html",
    styleUrls: ["./sponsor.component.scss"],
    standalone: true,
    imports: [NgFor, NgClass, AsyncPipe]
})
export class SponsorComponent implements OnInit {
  /** The api base uri, used for image src links. */
  public baseUrl = environment.mediaBaseURI;

  /** Observable that after all observables emit, emit values as an array */
  public activeSponsorsPerTier$: Observable<[Sponsor[], Sponsor[], Sponsor[]]> = new Observable();

  /** The loopIndexer, used for changing the sponsors every 5 seconds */
  public loopIndexer: number = 0;

  /** Set 5 second interval timer for the loopIndexer */
  runSponsorIndexerLoop() {
    setInterval(() => this.loopIndexer++, 5000);
  }

  /** Calls the SponsorService to fetch the Sponsors */
  constructor(private query: SponsorQuery, private service: SponsorService, private authQuery: AuthQuery) {
    /** update on intitial load */
    this.updatesponsor()
    /** update when logging in/out */
    this.authQuery.isLoggedIn$.subscribe({next:()=>{this.updatesponsor()}})

  }

  updatesponsor(){
    this.service
    .get({
      params: { page_size: 300 },
      mapResponseFn: (res: ApiResponse) => this.service.updateState(1, res),
    })
    .subscribe();
  }

  /** Fetches the active sponsors from the store */
  ngOnInit() {
    this.activeSponsorsPerTier$ = this.query.activeSponsorsPerTier$;
    // set pseudorandom starter, this starts a random sponsor from at most 100 sponsors, otherwise random from the first 100 sponsors.
    this.loopIndexer = Math.round(Math.random() * 100);
    this.runSponsorIndexerLoop();
  }
}
