import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { map, switchMap } from 'rxjs';
import { RouterQuery } from "@datorama/akita-ng-router-store";
import { CounterStore, CounterState } from './counter.store';

@Injectable({ providedIn: "root" })
export class CounterQuery extends QueryEntity<CounterState> {
  /** Counter Observable */
  public counters$ = this.selectAll();
  public counter$ = this.routerQuery
  .selectParams("id")
  .pipe(
    map((i) => {
      if (i) return i;
    })
  )
  .pipe(switchMap((i) => this.selectEntity(i)));

  constructor(protected store: CounterStore, public routerQuery: RouterQuery) {
    super(store);
  }

}

