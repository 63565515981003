export const PAGE_CLEARANCE_ROLE_MAP: any = {
  is_aspirant: [
    "algemeen",
    "activiteiten",
    "kleding",
    "assortiment",
    "tweedehands",
    "webshops",
    "commissies"
  ],
  is_ser_member: [
    "algemeen",
    "spammen",
    "frusten",
    "ledenlijst",
    "roeien",
    "blikkenlijst",
    "taartenlijst",
    "ergometerranglijst",
    "kleding",
    "assortiment",
    "tweedehands",
    "webshops",
  ],
  is_erelid_lvv: [
    "algemeen",
    "roeien",
    "downloads",
    "roeien",
    "blikkenlijst",
    "taartenlijst",
    "ergometerranglijst",
  ],
  is_employee: [
    "algemeen",
    "activiteiten",
    "verenigingshap"
  ]
};
