import { AfterContentInit, AfterViewInit, Component, ElementRef } from "@angular/core";
import { HeroImage, HeroImageService, HeroImageStore } from "./state";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

/**
 * The `esr-hero-image-banner` component serves as a visually appealing header
 * for the website. It rotates through different photos.
 *
 * The component smoothly transitions from the active photo into the website's
 * main background color using CSS shapes.
 */
@Component({
    selector: "esr-hero-image-banner",
    styleUrls: ["./hero-image-banner.component.scss"],
    templateUrl: "./hero-image-banner.component.html",
    standalone: true
})
export class HeroImageBannerComponent implements AfterContentInit {
    public lustrumImage: any;
    public image: any;

    constructor(
        private elRef: ElementRef,
        private service: HeroImageService,
        private router: Router
        ) {
            router.events.subscribe(val => {
                if (this.router.url.includes("lustrum")) {
                    this.elRef.nativeElement.style.setProperty('background-image', `url(${environment.mediaBaseURI + this.lustrumImage.photo_url})`)
                } else {
                    this.elRef.nativeElement.style.setProperty('background-image', `url(${environment.mediaBaseURI + this.image.photo_url})`)
                }
              })
        }
        
    ngAfterContentInit() {
        // get random image
        this.service.get({url: environment.apiBaseUri + '/heroimages/random/'}).subscribe({
            next: (res)=>{
                this.image = res as HeroImage
                this.service.updateState([this.image])
            }
        });

        this.service.get({url: environment.apiBaseUri + '/heroimages/lustrum/'}).subscribe({
            next: (res)=>{
                this.lustrumImage = res as HeroImage
                this.service.updateState([this.lustrumImage])
            }
        });
        
    }

}
