import { Component } from "@angular/core";
import { Observable } from "rxjs";
import { Announcement } from "./state/announcement.model";
import { AnnouncementService } from "./state/announcement.service";
import { DatePipe, NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common'
import { ApiResponse } from "../shared-resources/api-response";
import { AnnouncementQuery } from "./state";
import linkifyStr from 'linkify-string';


/**
 * Announcements can be a "Roeiverbod", "Object in the canal warning",
 * or anything else the Bestuur wants.
 */
@Component({
    selector: "esr-announcement",
    templateUrl: "./announcement.component.html",
    styleUrls: ["./announcement.component.scss"],
    providers: [DatePipe],
    standalone: true,
    imports: [NgIf, NgFor, NgClass, AsyncPipe]
})
export class AnnouncementComponent {
  /** Announcements observable, used for displaying in the html */
  public announcements$: Observable<Announcement[]> = this.query.items$;
  today = new Date();

  /** Announcement constructor */
  /** @ignore */
  constructor(
    private service: AnnouncementService,
    private query: AnnouncementQuery) {
    this.service.get({
      mapResponseFn: (res: ApiResponse) => res.results,
    }).subscribe();
  }

  // format date in typescript to compare to todays date+time
  getFormattedDate(date: string | Date): Date {
    return new Date(date);
  }

  linkify(description: string): string{
    return linkifyStr(description)
  }
}
