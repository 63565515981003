import { Injectable } from "@angular/core";
import { AnnouncementState, AnnouncementStore } from "./announcement.store";
import {
  NgEntityService,
  NgEntityServiceConfig,
} from "@datorama/akita-ng-entity-service";

@NgEntityServiceConfig({
  resourceName: "announcements",
})
@Injectable({ providedIn: "root" })
export class AnnouncementService extends NgEntityService<AnnouncementState> {
  constructor(
    protected store: AnnouncementStore,
    private announcementStore: AnnouncementStore
  ) {
    super(store);
  }
}
