import { Component } from "@angular/core";
import { environment } from "src/environments/environment";
import { AuthQuery } from "../login-module/auth/state";
import { ApiResponse } from "../shared-resources/api-response";
import { LinkQuery } from "./state/query";
import { LinkService } from "./state/service";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgIf, NgFor, AsyncPipe } from "@angular/common";
import { Router } from "@angular/router";

/**
 * LinksComponent displays the links in the sidebar.
 */
@Component({
    selector: "esr-links",
    templateUrl: "./links.component.html",
    styleUrls: ["./links.component.scss"],
    standalone: true,
    imports: [NgIf, NgFor, FontAwesomeModule, AsyncPipe]
})
export class LinksComponent {
  
  public lustrum = false;

  constructor(
    private query: LinkQuery,
    private service: LinkService,
    private authQuery: AuthQuery,
    private router: Router) {
      // update on load
    this.updateLinks();
    // update when user logs in/out
    this.authQuery.isLoggedIn$.subscribe({next:()=>{this.updateLinks()}})
    router.events.subscribe(val => {
      if (this.router.url.includes("lustrum")) {
        this.lustrum = true;
      } else {
        this.lustrum = false;
      }
    })
  }
  /**
   * Array of all links and icons objects.
   */
  public links$ = this.query.activeLinks$;
  public mediaBaseUrl = environment.mediaBaseURI;
  
  

  private updateLinks(){
    this.service
    .get({
      params: { page_size: 300 },
      mapResponseFn: (res: ApiResponse) => this.service.updateState(1, res),
    })
    .subscribe();
  }

}
