import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { CalendarEvent } from "./calendarEvent"


/**
 * GoogleCalendarApiService to fetch calendar events from the public Theta Google calendar.
 */
@Injectable({
  providedIn: "root",
})
export class GoogleCalendarApiService {
  /**
   * The base uri for the google calendar request.
   */
  public googleCalendarBaseUri = environment.googleCalendarBaseUri;
  /**
   * The google api key used for the google calendar request.
   */
  public googleApiKey = environment.googleApiKey;

  /**
   * Returns Ajax promise for calendar event items.
   */
  loadEvents(): Observable<CalendarEvent[]> {
    const startOfDay = new Date(new Date().setHours(0, 0, 0, 0));

    const queryParameters = Object.entries({
      key: this.googleApiKey,
      orderBy: "startTime",
      // Required for `orderBy` to expand recurring events into singleton ones
      singleEvents: "true",
      timeMin: startOfDay.toISOString(),
    })
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    // @ts-ignore
    return ajax(`${this.googleCalendarBaseUri}?${queryParameters}`).pipe(
      // @ts-ignore
      map(({ response: { items } }) =>
        items.map((event: any) => ({
          end: event.end.date,
          start: event.start.date,
          title: event.summary,
          uri: event.htmlLink,
        }))
      ),
      catchError((error) => {
        // TODO: Something better with these errors
        console.error.bind(error);
        return error;
      })
    );
  }
}
