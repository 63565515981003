import { Component } from "@angular/core";
import { filter, map, Observable, throttleTime } from "rxjs";
import { PhotoAlbum } from "../photo-album";
import { PhotoAlbumsQuery } from "../query";
import { PhotoAlbumsService } from "../service";
import { NgEntityServiceLoader } from "@datorama/akita-ng-entity-service";
import { environment } from "src/environments/environment";
import { PhotoAlbumsStore } from "../store";
import { NavigationQuery, NavigationService } from "src/app/navigation/state";
import { ApiResponse } from "src/app/shared-resources/api-response";
import { SpinnerComponent } from "../../spinner/spinner.component";
import { RouterLink } from "@angular/router";
import { NgIf, NgFor, AsyncPipe, DatePipe, CommonModule } from "@angular/common";
import { ContentComponent } from "../../content/content.component";
import { NavigationStore } from "src/app/navigation/state/navigation.store";
import { FormBuilder, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { switchMap } from 'rxjs/operators';



/** The PhotoAlbums Component */
@Component({
    selector: "esr-photo-album",
    templateUrl: "./photo-albums.component.html",
    styleUrls: ["./photo-albums.component.scss"],
    standalone: true,
    imports: [ContentComponent, NgIf, NgFor, RouterLink, SpinnerComponent, AsyncPipe, DatePipe, CommonModule, ReactiveFormsModule, FontAwesomeModule]
})
export class PhotoAlbumsComponent {
  /** Observable with all photo albums currently in the PhotoAlbumsStore */
  photoAlbums$: Observable<PhotoAlbum[]> = this.photoAlbumsQuery.photoAlbums$
  // album IDs of unread albums. Used in template to show unseen albums
  public unreadAlbumIDs$: Observable<number[]> = this.navigationQuery.unreadItems$.pipe(map(s => s.unreadAlbumIDs))
  /** Is true if there is a next page to fetch. */
  public hasNextPage$: Observable<boolean> = this.photoAlbumsQuery.select((state) => state.hasMore);
  /** The loaders object contains loading indicators for each of the PhotoAlbumsService methods */
  loaders = this.loader.loadersFor("photoAlbums");
  /** The api base uri.    */
  public baseUrl = environment.mediaBaseURI;
  /** list used to define the parameters for the backend */
  public queryParameters: any = {};
  
  // current year, for placeholder
  public currentYear = new Date().getFullYear();

  // form for searching albums
  public searchForm = this.formBuilder.group<{
    searchstring: string;
    year: number | undefined;
    photographer: string;
}>({
    searchstring: '',
    year: undefined,
    photographer: '',
  });
  
  // whenever unreadItems changes and unreadAlbums >0
  // get last 10 new photoalbums. This is in case someone has 
  // this page open for a long time. First 10 because counter also
  // increases when an album is updated and this gets the most cases
  // (not all edge cases like very old album being updated while the page is open)
  public unreadAlbums$ = this.navigationQuery.unreadItems$.pipe(
    filter((items) => items.unreadAlbums > 0),
    map((items) => {
      this.service
          .get({
            params: { order_by: "date_edited", page_size: items.unreadAlbums },
            mapResponseFn: (res: ApiResponse) => {
              // update store with the latest retrieved albums
              this.store.add(res.results, {prepend: true})
              // update navigationstore with the IDs of the retrievd albums
              this.navigationStore.update({
                unreadAlbumIDs: res.results.map((a) => (a as PhotoAlbum).id as number)
                })
            },
          }).subscribe();
      return items.unreadAlbums
    }))

  /** @ignore */
  constructor(
    private photoAlbumsQuery: PhotoAlbumsQuery,
    private service: PhotoAlbumsService,
    private store: PhotoAlbumsStore,
    private loader: NgEntityServiceLoader,
    private navigationService: NavigationService,
    private navigationQuery: NavigationQuery,
    private navigationStore: NavigationStore,
    private formBuilder: FormBuilder,
  ) {
    navigationService.getUnreadMessages()

    this.searchForm.valueChanges.pipe(
      switchMap((value) => {
        this.queryParameters = { order_by : 'album_date'};
        // search by title
        this.queryParameters['filter_title_ilike'] = this.searchForm.value.searchstring;
        // search by year
        const searchYear = value.year;
        if (searchYear && (searchYear?.toString().length < 4 || searchYear?.toString().length > 4)){
          // user is typing, but year is not complete. Do not search yet
          return new Observable();
        }
        if(searchYear?.toString().length === 4){
          this.queryParameters['filter_album_date_lt'] = `${searchYear+1}-01-01`;
          this.queryParameters['filter_album_date_gt'] = `${searchYear}-01-01`;
        }
        else{
          delete this.queryParameters['filter_album_date_lt'];
          delete this.queryParameters['filter_album_date_gt'];
        }
        
        // search by photographer
        this.queryParameters['filter_photographer_ilike'] = value.photographer;

        this.store.setLoading(true);
        return this.service.get({
          params:{...this.queryParameters, page_size: 12 },
          mapResponseFn: (res: any) => {
            this.store.reset();
            this.service.updatePhotoAlbumsStore(1, res);
          },
        });
      })
    ).subscribe({next: () => this.store.setLoading(false)});
  }
  
  /** If there is a next page, it calls the PhotoAlbumsService to fetch the next page. */
  getNextPage() {
    if (this.photoAlbumsQuery.getHasMore()) {
      const nextPage = this.photoAlbumsQuery.getPage();
      this.service
        .get({
          params: { ...this.queryParameters, page: nextPage, page_size: 12 },
          mapResponseFn: (res: any) =>
            this.service.updatePhotoAlbumsStore(nextPage, res),
        })
        .subscribe();
    }
  }
}
