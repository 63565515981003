import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { NgEntityService, NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { CounterState, CounterStore } from './counter.store';

@Injectable({ providedIn: "root" })
@NgEntityServiceConfig({
  resourceName: "counters",
})
export class CounterService extends NgEntityService<CounterState>{

  constructor(protected counterStore: CounterStore) {
    super(counterStore);
  }

  @transaction()
  updateState(res: any) {
    if (res) {
      this.store.add(res.results);
      this.store.setLoading(false);
    }

  }

}
