import { Injectable } from "@angular/core";
import { transaction } from "@datorama/akita";
import {
  NgEntityService,
  NgEntityServiceConfig,
} from "@datorama/akita-ng-entity-service";
import { ApiResponse } from "src/app/shared-resources/api-response";
import { LinkState, LinkStore } from "./store";


/**
 * SponsorService extends the NgEntityService from akita.
 * https://engineering.datorama.com/introducing-akitas-new-entity-service-at-your-service-c511a82fba22
 *
 * Akita entity service follows the standard RESTful naming conventions by default.
 *
 * It implements the GET, POST, UPDATE, DELETE functionality.
 *
 * The endpoint is added in the service constructor
 */
@Injectable({ providedIn: "root" })
@NgEntityServiceConfig({
  resourceName: "links",
})
export class LinkService extends NgEntityService<LinkState> {
  /**
   * NewsService constructor.
   * @param store The store for the sponsoritems.
   */
  constructor(protected store: LinkStore) {
    super(store);
  }

    /**
   * Transactions are an optimization for performing multiple operations on the store.
   * See Akita documentation: https://datorama.github.io/akita/docs/transactions/
   * @param page The current page number
   * @param res The response from the backend
   */
     @transaction()
     updateState(page: number, res: ApiResponse) {
       if(res.results){
        const nextPage = page + 1;
        const hasMore = res.next ? true : false;
        this.store.add(res.results);
        this.store.updateState({ hasMore, nextPage });
        this.store.setLoading(false);
       }
       
     }
}
