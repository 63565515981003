import { Injectable } from "@angular/core";
import { QueryEntity } from "@datorama/akita";
import { RouterQuery } from "@datorama/akita-ng-router-store";
import { Observable } from "rxjs/internal/Observable";
import { map, switchMap } from "rxjs/operators";
import { News } from "./news.model";

import { NewsStore, NewsState } from "./news.store";

/**
 * A Query is a class offering functionality responsible for querying the store.
 * You can think of the query as being similar to database queries.
 * Its constructor function receives as parameters its own store and possibly other query classes.
 */
@Injectable({ providedIn: "root" })
export class NewsQuery extends QueryEntity<NewsState> {
  /** Observable with all items currently in the store.
   *  Puts pinnend news items on top.
   */
  public items$: Observable<News[]> = this.selectAll().pipe(
    map((items: News[]) => {
      const pinnedNewsItems: News[] = [];
      let filteredNewsItems: News[] = items;

      items.map((newsItem: News) => {
        if (newsItem.on_top) {
          pinnedNewsItems.push(newsItem);
          filteredNewsItems = filteredNewsItems.filter(
            (item) => item.id !== newsItem.id
          );
        }
      });

      return [...pinnedNewsItems, ...filteredNewsItems];
    })
  );
  /**
   * Observable of item selected by route id parameter.
   */
  public item$ = this.query
    .selectParams("id")
    .pipe(
      map((i) => {
        if (i) return i;
      })
    )
    .pipe(switchMap((i) => this.selectEntity(i)));

  /** @ignore */
  constructor(private query: RouterQuery, protected store: NewsStore) {
    super(store);
  }

  /**
   * Returns a boolean whether there is a next page to fetch.
   */
  getHasMore() {
    return this.getValue().hasMore;
  }

  /**
   * Returns the next page number to fetch.
   */
  getPage() {
    return this.getValue().nextPage;
  }
}
