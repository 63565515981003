import { Route } from "@angular/router";

import { NewsComponent } from "./news/news-list/news.component";
import { NewsItemComponent } from "./news/news-item/news-item.component";
import { NewsResolver } from "./news/news-list/resolver";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { AuthGuard } from "./login-module/auth/auth-guard.service";
import { HalfPageComponent } from "./website/half-page/half-page.component";
import { ROLES_HIERARCHY } from "./login-module/auth/roles-and-permissions";
import { PhotoAlbumsComponent } from "./photo-albums/photo-albums-list/photo-albums.component";
import { PhotoAlbumsResolver } from "./photo-albums/resolver";
import { PhotoAlbumComponent } from "./photo-albums/photo-album-item/photo-album.component";

export default [
  {
    path: "leden/beheer",
    loadChildren: () => import("./user-module/admin-module/routes"),
    canActivate: [AuthGuard],
    data: {
      expectedRoles: ROLES_HIERARCHY.beheer,
    },
  },
  {
    path: "",
    component: HalfPageComponent,
    children: [
      { path: "", pathMatch: "full", redirectTo: "news" },
      {
        path: "leden",
        loadChildren: () => import("./user-module/routes"),
        canLoad: [AuthGuard],
      },
      {
        path: "publiek",
        loadChildren: () =>
          import("./public-static-pages/routes"),
      },
      {
        path: "login",
        loadChildren: () =>
          import("./login-module/routes"),
      },
      {
        path: "publiek/zakelijk/vacatures",
        loadChildren: () =>
          import("./vacatures/routes"),
      },
      {
        path: "publiek/lustrum",
        loadChildren: () =>
          import("./lustrum-page/routes"),
      },
      {
        path: "news",
        children: [
          {
            path: ":id",
            component: NewsItemComponent,
            resolve: {
              newsItem: NewsResolver,
            },
            runGuardsAndResolvers: "always",
          },
          {
            path: "",
            component: NewsComponent,
            resolve: {
              newsItems: NewsResolver,
            },
            runGuardsAndResolvers: "always",
          }
        ],
      },
      {
        path: "publiek/vereniging/bestuur",
        loadChildren: () =>
          import("./bestuur-module/routes")
      },
      {
        path: "publiek/vereniging/botenvloot",
        loadChildren: () =>
          import("./boats-module/routes"),
      },
    ],
  },
  {
    path: "publiek/photoalbums",
    children: [
      {
        path: ":id",
        component: PhotoAlbumComponent,
        resolve: {
          photoalbums: PhotoAlbumsResolver,
        },
      },
      {
        path: "",
        component: PhotoAlbumsComponent,
        resolve: {
          photoalbums: PhotoAlbumsResolver,
        },
        runGuardsAndResolvers: "always"
      },
    ],
  },
  { path: "**", component: PageNotFoundComponent },
] as Route[]