import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { HeroImage } from './hero-image.model';

export interface HeroImageState extends EntityState<HeroImage>  {
  enabled: boolean;
}

const initialState: HeroImageState = {
  enabled: false,
};

@Injectable({ providedIn: "root" })
@StoreConfig({ name: 'heroimages' })
export class HeroImageStore extends EntityStore<HeroImageState> {
  /** @ignore */
  constructor() {
    super(initialState);
  }

}

