import { Injectable } from "@angular/core";
import { QueryEntity } from "@datorama/akita";
import { RouterQuery } from "@datorama/akita-ng-router-store";
import { Observable, zip } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { Sponsor } from "./sponsor";
import { SponsorStore, SponsorState } from "./store";

/**
 * A Query is a class offering functionality responsible for querying the store.
 * You can think of the query as being similar to database queries.
 * Its constructor function receives as parameters its own store and possibly other query classes.
 */
@Injectable({ providedIn: "root" })
export class SponsorQuery extends QueryEntity<SponsorState> {
  /** Observable of all sponsors */
  public allSponsors$: Observable<Sponsor[]> = this.selectAll();
  /**
  * Observable of item selected by route id parameter.
  */
   public sponsor$ = this.routerQuery
   .selectParams("id")
   .pipe(
     map((i) => {
       if (i) return i;
     })
   )
   .pipe(switchMap((i) => this.selectEntity(i)));
  /** Observable active gold sponsors */
  public activeGoldSponsors$: Observable<Sponsor[]> = this.allSponsors$.pipe(
    map((sponsors) =>
      sponsors.filter((sponsor: Sponsor) => sponsor.sponsor_tier === "gold")
    )
  );

  /** Observable of active silver sponsors */
  public activeSilverSponsors$: Observable<Sponsor[]> = this.allSponsors$.pipe(
    map((sponsors) =>
      sponsors.filter((sponsor: Sponsor) => sponsor.sponsor_tier === "silver")
    )
  );

  /** Observable of active bronze sponsors */
  public activeBronzeSponsors$: Observable<Sponsor[]> = this.allSponsors$.pipe(
    map((sponsors) =>
      sponsors.filter(
        (sponsor: Sponsor) =>
          sponsor.sponsor_tier === "bronze" && sponsor.is_active === true
      )
    )
  );

  /** Observable that after all observables emit, emit values as an array */
  public activeSponsorsPerTier$ = zip(
    this.activeGoldSponsors$,
    this.activeSilverSponsors$,
    this.activeBronzeSponsors$
  );

  /** @ignore */
  constructor(protected store: SponsorStore, public routerQuery: RouterQuery) {
    super(store);
  }

    /** Returns a boolean whether there is a next page to fetch. */
    getHasMore() {
      return this.getValue().hasMore;
    }
  
    /** Returns the next page number to fetch. */
    getPage() {
      return this.getValue().nextPage;
    }
}
