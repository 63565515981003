import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient } from "@angular/common/http";
import { tap } from "rxjs/operators";

import { AuthStore } from "./auth.store";
import { environment } from "../../../../environments/environment";
import { Router } from "@angular/router";
import { User } from "./user.model";
import { ToastrService } from "ngx-toastr";
import { resetStores } from "@datorama/akita";
import { UserStore } from "src/app/user-module/user-profile/state";

/** The login repsonse interface from the backend */
interface AuthResponse {
  /** The JWT from the backend, used to authenticate the requests */
  access_token: string;
  // the refresh token
  refresh_token: string;
  /** The current user */
  user: User;
}

interface RefreshResponse {
  /** The JWT from the backend, used to authenticate the requests */
  access_token: string;
}

/** AuthService provides the login and logout functionality. */
@Injectable({ providedIn: "root" })
export class AuthService {
  /** @ignore */
  constructor(
    private http: HttpClient,
    private httpBackend: HttpBackend,
    private authStore: AuthStore,
    private userStore: UserStore,
    private router: Router,
    private toastr: ToastrService
  ) {}

  /**
   * Given a username and password fetches the authorization token from the backend
   * and updates the authstore with the authorization token.
   * @param cred The login username and password credentials
   */
  login(cred: {username: string, password: string}) {
    return this.http
      .post<AuthResponse>(`${environment.apiBaseUri}/login`, cred)
      .pipe(
        tap((response) => {
          this.authStore.update({ user: response.user });
          this.authStore.update({ access_token: response.access_token.toString() });
          this.authStore.update({ refresh_token: response.refresh_token.toString() });
          // this.authStore.update({ roles: response.user.roles });
        })
      );
  }

  refresh(refresh_token: string){
    // refresh access token using the refresh token
    const newHttpClient = new HttpClient(this.httpBackend);
    return newHttpClient.
      post<RefreshResponse>(`${environment.apiBaseUri}/login/refresh/`, undefined,
      { headers: {'Authorization': refresh_token}}).pipe(
        tap((response) =>{
          this.authStore.update({access_token: response.access_token});
          this.updateLoggedInUser();
        }
      ))
  }

  updateLoggedInUser(){
    return this.http
      .get<User>(`${environment.apiBaseUri}/users/me`)
      .pipe(
        tap((response) => {
          this.authStore.update({ user: response });
        })
      );
  }

  passwordupdate(resetForm: {username: string | undefined, email: string | undefined}){
    const body = resetForm.username? {"username":resetForm.username} : {"email":resetForm.email};
    return this.http.
      post<{status:string}>(`${environment.apiBaseUri}/login/passwordreset`, body)
  }

  gegevensupdate(){
    return this.http.
      post<{status:string}>(`${environment.apiBaseUri}/users/me/gegevensupdate/`, null);
  }


  /** Resets the authstore and removes the authorization token */
  logout() {
    resetStores()
    localStorage.removeItem("AkitaStores");
    this.router.navigate(["/"]);
    this.toastr.success("Je bent uitgelogd");
  }
}
