<div *ngIf="latestPoll$ | async as poll">
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">Poll</p>
    </header>
    <div class="card-content">
      <p class="title is-6">
        {{poll.question}}
      </p>
      <form *ngIf="canVote$ | async; else results" [formGroup]="pollForm" (ngSubmit)="submit()">
        <div *ngFor="let voteOption of poll.options" class="control">
          <label class="radio">
            <input type="radio" value="{{voteOption.id}}" formControlName="option">
            {{voteOption.option}}
          </label>
        </div>
        <div class="mt-4">
          <button *ngIf="this.pollForm.valid; else elseBlock" class="button" type="submit">
            Stem
          </button>
          <h2 *ngIf="lustrum"></h2>
          <ng-template #elseBlock>
            <button class="button" type="submit" disabled>
              Stem
            </button>
            <h2 *ngIf="lustrum"></h2>
          </ng-template>
        </div>
      </form>
      <ng-template #results>
        <p class="subtitle is-6">
          Aantal stemmen: {{totalVotes()}}
        </p>
        <div *ngFor="let voteOption of poll.options" class="mt-4">
          {{voteOption.option}}: {{voteOption.no_of_votes}} 
          <progress *ngIf="!lustrum" class="progress is-primary is-small" value="{{voteOption.no_of_votes}}" max="{{totalVotes()}}"></progress>
          <div class="lustrum" *ngIf="lustrum">
            <progress class="progress is-small" value="{{voteOption.no_of_votes}}" max="{{totalVotes()}}"></progress>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <h2 *ngIf="lustrum"></h2>
</div>