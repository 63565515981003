import { Component } from "@angular/core";
import { map, Observable } from "rxjs";
import { AuthQuery } from "../login-module/auth/state";
import { PUBLIC_PAGE_SLUGS } from "../shared-resources/page-slugs";
import { NavigationQuery } from "./state";
import { MemberNavbarComponent } from "./member-navbar/member-navbar.component";
import { MobileNavbarComponent } from "./mobile-navbar/mobile-navbar.component";
import { NgIf, NgFor, AsyncPipe } from "@angular/common";
import { Router, RouterLink } from "@angular/router";

/**
 * Open and close the hamburger menu on mobile devices.
 * Add event listener does not work on iOS which is why we now added
 * the explicit (click) event to the html file.
 */
export const toggleMobileMenu = () => {
  const $navbarBurger = document.getElementById("navbarBurger");

  if (!$navbarBurger) return
  const target = $navbarBurger.dataset.target;

  if (!target) return
  const $target = document.getElementById(target);

  if (!$target) return
  $navbarBurger.classList.toggle("is-active");
  $target.classList.toggle("is-active");
};

/**
 * The `esr-navigation` component contains the main navigation for the website.
 *
 * Its content depends on the logged in status of the user. When logged in, a
 * second navigation bar is visible linking to content that is only available
 * given that context.
 */
@Component({
    selector: "esr-navigation",
    templateUrl: "./navigation.component.html",
    styleUrls: ["./navigation.component.scss"],
    standalone: true,
    imports: [RouterLink, NgIf, NgFor, MobileNavbarComponent, MemberNavbarComponent, AsyncPipe]
})
export class NavigationComponent {
  /** Boolean observable to toggle the leden navbar */
  public isLoggedIn$ = false;

  public lustrum = false;
  /** Variable of the toggleMobileMenu function so the html template can use it */
  public toggleMobileMenu = toggleMobileMenu;
  /** Copy of PUBLIC_PAGE_SLUGS so the component template can loop though the pages. */
  public publicPageSlugs = Object.keys(PUBLIC_PAGE_SLUGS);
  /** Boolean, used to determine whether to load the mobile navigation component */
  public isMobile = window.innerWidth < 1024 ? true : false;
  /** Given a page-slug return whether it has sub pages */
  public hasSubPages = (page: string) =>
    Object.keys(PUBLIC_PAGE_SLUGS[page]).length > 1 ? true : false;
  /** Given a page-slug returns its sub pages */
  public getSubPages = (page: string) => Object.keys(PUBLIC_PAGE_SLUGS[page]).slice(1);
  /** Given a page-slug returns the human readable format */
  public getPageName = (page: string, parent?: string) =>
    parent ? PUBLIC_PAGE_SLUGS[parent][page] : PUBLIC_PAGE_SLUGS[page][page];
  /** Given a resize event sets the `isMobile` variable */
  onResize(event: any) {
    this.isMobile = event.target.innerWidth < 1024 ? true : false;
  }

  public unreadAlbums$ = this.navigationQuery.unreadItems$.pipe(map((items) => {
    return items.unreadAlbums
  }));

  public unreadIndicator$: Observable<boolean> = this.navigationQuery.unreadItems$.pipe(map((counters) => {
    return counters.unreadFrusts > 0 || counters.unreadSpams > 0 || counters.unreadAlbums > 0
  }));

  constructor(private authQuery: AuthQuery,
    protected navigationQuery: NavigationQuery,
    private router: Router) {
    router.events.subscribe(val => {
      if (this.router.url.includes("lustrum")) {
        this.lustrum = true;
      } else {
        this.lustrum = false;
      }
    })

    


    this.authQuery.isLoggedIn$.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn$ = isLoggedIn;
      }
    );

  }
}
