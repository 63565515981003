<div class="modal" [ngClass]="{ 'is-active' : isActive }">
  <div class="modal-background" (click)="closeModal()"></div>
  <div class="modal-card card">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ title }}</p>
      <h2 *ngIf="lustrum"></h2>
      <button class="delete" aria-label="close" (click)="closeModal()"></button>
    </header>
    <div  *ngIf="!lustrum"></div>
    <section class="modal-card-body">
      <!-- Content ... -->
      <ng-content select="[content]"></ng-content>
    </section>
    <footer class="modal-card-foot" *ngIf="!lustrum">
      <!-- Footer button(s) ... -->
      <button
        class="submit-button"
        class="button is-primary"
        (click)="onSuccess()"
        footer
      >
        {{submitString}}
      </button>
    </footer>
  </div>
</div>

