import { Injectable } from "@angular/core";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { PhotoAlbum } from "./photo-album";

/**
 * PhotoAlbums State. Extends the entity state functionality from akita.
 */
export interface PhotoAlbumsState extends EntityState<PhotoAlbum> {
  hasMore: boolean;
  nextPage: number;
}

/**
 * The initial PhotoAlbumsState
 * hasMore - Boolean whether there is a next page to fetch
 * nextPage - The next page to fetch from the backend
 */
const initialState: PhotoAlbumsState = {
  hasMore: true,
  nextPage: 1,
};

/**
 * PhotoAlbums Store. Extends the store functionality from akita.
 * The Store is a single object which contains the store state and serves as the "single source of truth."
 */
@Injectable({ providedIn: "root" })
@StoreConfig({ name: "photoAlbums" })
export class PhotoAlbumsStore extends EntityStore<PhotoAlbumsState> {
  /**
   * PhotoAlbumsStore constructor.
   */
  constructor() {
    super(initialState);
  }

  /**
   * Given a page number updates the page number.
   * @param state The new state
   */
  updateState(state: { hasMore: boolean; nextPage: number }) {
    this.update(state);
  }
}
