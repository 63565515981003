import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";
import { User } from "src/app/login-module/auth/state/user.model";

/** The State interface */
export interface AuthState {
  user: User;
  /** The JWT from the backend, used to authenticate the requests */
  access_token: string;

  refresh_token: string;
  /** The user's roles, used for access control of restricted actions */
  // roles: Role[];
}

/** Function to initialize the store */
export function createInitialState(): AuthState {
  return {
    user: {
      id: 0,
      first_name: "",
      frust_count: 0,
      last_name: "",
      middle_name: "",
      name: "",
      photo_url: "",
      roles: [],
      spam_count: 0,
      zuur: 0,
      token: "",
    },
    access_token: "",
    refresh_token: "",
    // roles: [],
  };
}

/** The Store is a single object which contains the store state and serves as the single source of truth. */
@Injectable({ providedIn: "root" })
@StoreConfig({ name: "auth", resettable: true })
export class AuthStore extends Store<AuthState> {
  /** @ignore */
  constructor() {
    super(createInitialState());
  }
}
