<div class="announcements" *ngIf="announcements$">
  <div *ngFor="let announcement of announcements$ | async">
    <div *ngIf="getFormattedDate(announcement.enabled_from) < today && getFormattedDate(announcement.enabled_until) > today">
      <div
      class="notification"
      [ngClass]="['roeiverbod', 'warning', 'announcement'][ announcement.level ]"
      >
        <div>
          <p class="title is-4">{{ announcement.title }}</p>
          <p [innerHTML]="linkify(announcement.description)"></p>
        </div>
      </div>
    </div>
  </div>
</div>