import { Component } from "@angular/core";
import { AuthQuery, AuthService } from "../login-module/auth/state";
import { UserSettingsQuery } from "../user-settings/query";

import { environment } from "../../environments/environment";
import { map } from "rxjs";
import { Role } from "../login-module/auth/role.enum";
import { SponsorComponent } from "../sponsor/sponsor.component";
import { GoogleCalendarComponent } from "../google-calendar/google-calendar.component";
import { PollComponent } from "../user-module/poll/poll.component";
import { NgIf, AsyncPipe } from "@angular/common";
import { CounterComponent } from "../counter/counter.component";
import { LinksComponent } from "../links/links.component";
import { AnnouncementComponent } from "../announcement/announcement.component";
import { Router } from "@angular/router";

/**
 * The `esr-sidebar` component contains elements visible to the side of the
 * main content of the website. These elements tend to relate to concerns
 * orthogonal to the main contents of the website. For instance:
 *
 * * miscellaneous links to related applications
 * * a login or logout link
 * * sponsor logos
 * * a calendar of upcoming events
 *
 * The content of the sidebar changes based on whether a user is logged in.
 */
@Component({
    selector: "esr-sidebar",
    styleUrls: ["./sidebar.component.scss"],
    templateUrl: "./sidebar.component.html",
    standalone: true,
    imports: [AnnouncementComponent, LinksComponent, CounterComponent, NgIf, PollComponent, GoogleCalendarComponent, SponsorComponent, AsyncPipe]
})
export class SidebarComponent {
  /** Boolean observable to toggle between login and logout in the sidebar */
  isLoggedIn$ = this.authQuery.isLoggedIn$;

  public lustrum = false;
  /** Boolean observable show poll or not */
  isMember$ = this.authQuery.roles$.pipe(map((roles) => roles.includes(Role.Member)));
  /** Boolean to link to the old login page on production */
  public isProduction = environment.production;

  /** @ignore */
  constructor(
    private userSettingsQuery: UserSettingsQuery,
    private authQuery: AuthQuery,
    private authService: AuthService,
    private router: Router
  ) {
    router.events.subscribe(val => {
      if (this.router.url.includes("lustrum")) {
        this.lustrum = true;
      } else {
        this.lustrum = false;
      }
    })
  }
}
