import { Component } from "@angular/core";
import { News, NewsQuery, NewsService } from "../state";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterQuery } from "@datorama/akita-ng-router-store";
import { ToastrService } from "ngx-toastr";
import { AuthQuery } from "src/app/login-module/auth/state";
import { Role } from "src/app/login-module/auth/role.enum";
import { NewsCommentComponent } from "../news-comment/news-comment.component";
import { MarkdownModule } from "ngx-markdown";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ContentComponent } from "../../content/content.component";
import { NgIf, NgFor, AsyncPipe, DatePipe } from "@angular/common";

/**
 * NewsItemComponent fetches the news item from the store via the NewsQuery.
 */
@Component({
    selector: "esr-news-item",
    templateUrl: "./news-item.component.html",
    styleUrls: ["./news-item.component.scss"],
    standalone: true,
    imports: [NgIf, ContentComponent, FontAwesomeModule, MarkdownModule, NgFor, NewsCommentComponent, FormsModule, ReactiveFormsModule, AsyncPipe, DatePipe]
})
export class NewsItemComponent {
  public commentForm = this.formBuilder.nonNullable.group({
    chatText: ["", Validators.required]
  }
  )
  public newsId: number | null = this.routerQuery.getParams("id");
  public roles$ = this.authQuery.roles$;
  public Role = Role;
  /** All news items in the new store. */
  public newsItem$ = this.query.item$.pipe(
    map((item: News | undefined) => {
      const newItem = { ...item };

      if (item) {
        const image = item.photo_url
          ? environment.mediaBaseURI + item.photo_url
          : "";
        const attachment = item.attachment_url
          ? environment.mediaBaseURI + item.attachment_url
          : "";

        newItem.photo_url = image;
        newItem.attachment_url = attachment;
      }

      return newItem;
    })
  );
  /** The api base uri, used for image src links. */
  public baseUrl = environment.mediaBaseURI;

  /** @ignore */
  constructor(
    private query: NewsQuery,
    private service: NewsService,
    private routerQuery: RouterQuery,
    private toastr: ToastrService,
    private authQuery: AuthQuery,
    private formBuilder: FormBuilder,
  ) {}

  submit() {
    if (this.commentForm.valid && this.newsId) {
      this.service
        .postcomment(this.commentForm.controls["chatText"].getRawValue(), this.newsId)
        .subscribe({
          next: () => {
            this.service.get(this.newsId).subscribe({
              error: () => {
                // if the newsitem could not get refreshed
                this.toastr.error("Refresh de pagina");
              },
            });
            this.commentForm.reset()
          },
          error: () => {
            this.toastr.error("Error bij plaatsen comment");
          },
        });
    }
  }
}
