import { AfterViewInit, Component } from "@angular/core";

import { environment } from "../../../environments/environment";
import { PhotoAlbumsQuery } from "../query";
import { ContentComponent } from "../../content/content.component";
import { NgIf, NgFor, AsyncPipe, DatePipe } from "@angular/common";
// @ts-expect-error
import GLightbox from 'glightbox';


/** The Photo album component */
@Component({
    selector: "esr-photo-album",
    templateUrl: "./photo-album.component.html",
    styleUrls: ["./photo-album.component.scss"],
    standalone: true,
    imports: [NgIf, ContentComponent, NgFor, AsyncPipe, DatePipe]
})
export class PhotoAlbumComponent implements AfterViewInit {
  /** Observable of the selected photo album */
  public photoAlbum$ = this.photoAlbumQuery.photoAlbum$;
  /** The api base uri. */
  public baseUrl: string = environment.mediaBaseURI;
  /** @ignore */
  constructor(private photoAlbumQuery: PhotoAlbumsQuery) {
    
  }
  ngAfterViewInit(): void {
    // init the lightbox. Creates lightbox gallery of all the .glightbox class in the html
    const lightbox = GLightbox({
      
    });
  }

}
