import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { map, switchMap } from 'rxjs';
import { RouterQuery } from "@datorama/akita-ng-router-store";
import { HeroImageState, HeroImageStore } from './hero-image.store';

@Injectable({ providedIn: "root" })
export class HeroImageQuery extends QueryEntity<HeroImageState> {
  /** Counter Observable */
  public heroimages$ = this.selectAll();
  public heroimage$ = this.routerQuery
  .selectParams("id")
  .pipe(
    map((i) => {
      if (i) return i;
    })
  )
  .pipe(switchMap((i) => this.selectEntity(i)));

  constructor(protected store: HeroImageStore, public routerQuery: RouterQuery) {
    super(store);
  }

}

