import { NgIf } from "@angular/common";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

/**
 * The `esr-footer` component contains basic contact information, links to
 * social media accounts and regulatory information.
 */
@Component({
    selector: "esr-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
    standalone: true,
    imports: [NgIf, FontAwesomeModule]
})
export class FooterComponent {
    public lustrum = false;

    constructor(private router: Router) {
        router.events.subscribe(val => {
            if (this.router.url.includes("lustrum")) {
              this.lustrum = true;
            } else {
              this.lustrum = false;
            }
          })
    }
}
