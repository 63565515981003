import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "truncate",
  standalone: true,
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, number: number): string {
    let regexp = new RegExp("(([^\\s]+\\s\\s*){" + number + "})([\\S\\s]*)", "g")
    return value.replace(regexp, "$1...");
  }
}
