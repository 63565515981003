import { Component } from "@angular/core";
import { filter, map, Observable, switchMap, timer } from "rxjs";

import { AuthQuery, AuthService } from "../../login-module/auth/state";
import { User } from "../../login-module/auth/state/user.model";
import { MEMBER_PAGE_SLUGS } from "../../shared-resources/page-slugs";
import { PAGE_CLEARANCE_ROLE_MAP } from "../Page-Role-Map";
import { Role } from "../../login-module/auth/role.enum";
import { nestedOmit, omit } from "../../shared-resources/helper-functions";
import { environment } from "src/environments/environment";
import { NavigationQuery, NavigationService } from "../state";
import { Router, RouterLink } from "@angular/router";
import { NgIf, NgFor, AsyncPipe } from "@angular/common";

/** Filter out any Slugs that the current user is not allowed to navigate to */
export const filterPageSlugsOnAuthorizationClearance = (
  roles: Role[],
  slugs: { [key: string]: { [key: string]: string } },
) => {
  const slugsDict: any = JSON.parse(JSON.stringify(slugs));
  if (roles && roles.indexOf(Role.Member) === -1) {
    Object.keys(slugsDict).forEach((menuTitle) => {
      Object.keys(slugsDict[menuTitle]).forEach((slug) => {
        // if slug is not in any of the roles, remove it
        let shouldRemove = true;
        roles.forEach((role) => {
          if (PAGE_CLEARANCE_ROLE_MAP[role] && PAGE_CLEARANCE_ROLE_MAP[role].includes(slug)) {
            shouldRemove = false;
          }
        });
        if (shouldRemove) {
          delete slugsDict[menuTitle][slug];
        }
      });
    });
    return slugsDict;

  } else {
    return slugsDict;
  }
};

/** The MemberNavbar component */
@Component({
    selector: "esr-member-navbar",
    templateUrl: "./member-navbar.component.html",
    styleUrls: ["./member-navbar.component.scss"],
    standalone: true,
    imports: [NgIf, NgFor, RouterLink, AsyncPipe]
})
export class MemberNavbarComponent {
  
  public lustrum = false;

  /** @ignore */
  constructor(
    private authQuery: AuthQuery,
    private authService: AuthService,
    private navigationService: NavigationService,
    private navigationQuery: NavigationQuery,
    private router: Router
  ) {
    /** Poll for unread messages every 10 seconds */
    authQuery.roles$.pipe(
      // run on each change in roles
      filter((roles) => roles.includes(Role.Member) || roles.includes(Role.SerMember)),
      // when filtered on role includes member or sermember switch this observable to a 10 sec timer
      switchMap(() => {
        return timer(0, 1000*10)
      })
    ).subscribe(()=>{
      // subscribe to the 10 sec timer to get messages
      this.navigationService.getUnreadMessages();
    })

    router.events.subscribe(val => {
      if (this.router.url.includes("lustrum")) {
        this.lustrum = true;
      } else {
        this.lustrum = false;
      }
    })

    authQuery.roles$.subscribe((roles) => {
      this.allowedMemberPageSlugs = filterPageSlugsOnAuthorizationClearance(
        roles,
        MEMBER_PAGE_SLUGS
      );
      this.memberPageSlugs = Object.keys(this.allowedMemberPageSlugs).filter(
        (pageSlug) => {
          return (
            Object.entries(this.allowedMemberPageSlugs[pageSlug]).length > 0
          );
        }
      );
    });
  }
  public allowedMemberPageSlugs: any;
  /** Copy of MEMBER_PAGE_SLUGS so the component template can loop though the pages. */
  public memberPageSlugs: any;
  /** Boolean observable to toggle the leden navbar */
  public isLoggedIn$: Observable<boolean> = this.authQuery.isLoggedIn$;
  /** Observable of the current User */
  public user$: Observable<User> = this.authQuery.user$;
  /** Observable if the user is an Admin */
  public isAdmin$ = this.authQuery.isAdmin$;
  /** Given a page-slug return whether it has sub pages */
  public hasSubPages = (page: string) =>
    Object.keys(this.allowedMemberPageSlugs[page]).length > 1 ? true : false;
  /** Given a page-slug returns its sub pages */
  public getSubPages = (page: string) =>
    Object.keys(this.allowedMemberPageSlugs[page]).slice(1);
  /** Base Url for the site, used for the foto */
  public baseUrl = environment.mediaBaseURI;

  /** Unread spams/frusts for badge notification */
  public unreadSpams$ = this.navigationQuery.unreadItems$.pipe(map((items) => { return items.unreadSpams }))
  public unreadFrusts$ = this.navigationQuery.unreadItems$.pipe(map((items) => { return items.unreadFrusts }))
  public unreadAlbums$ = this.navigationQuery.unreadItems$.pipe(map((items) => { return items.unreadAlbums }))

  /** Given a page-slug returns the human readable format */
  public getPageName = (page: string, parent?: string) =>
    parent
      ? this.allowedMemberPageSlugs[parent][page]
      : this.allowedMemberPageSlugs[page][page];

  /** Call the authService to log out */
  logout() {
    this.authService.logout();
  }


}
