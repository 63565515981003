<div class="box p-0">
  <header class="card-header">
    <p class="card-header-title" *ngIf="header">
      {{ header }}
    </p>
  </header>
  <figure *ngIf="image" class="image">
    <img
      style="border-top-left-radius: 0.25rem; border-top-right-radius: 0.25rem; object-fit: cover;" 
      alt="Card Image"
      src="{{image}}"
    />
  </figure>
  <div class="card-content">
    <ng-content></ng-content>
  </div>
  <footer class="card-footer" *ngIf="footer">
    <a [routerLink]="footer" class="card-footer-item">Meer</a>
  </footer>
</div>
