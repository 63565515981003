import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { NgEntityService, NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { tap } from 'rxjs';
import { ApiResponse } from 'src/app/shared-resources/api-response';
import { environment } from 'src/environments/environment';
import { Poll } from './poll.model';
import { PollState, PollStore } from './poll.store';


@NgEntityServiceConfig({
  resourceName: "polls",
})
@Injectable({ providedIn: "root" })
export class PollService extends NgEntityService<PollState> {

  constructor(private pollStore: PollStore, private httpservice: HttpClient) {
    super(pollStore)
  }

  @transaction()
  updateState(page: number, res: ApiResponse) {
    if (res.results) {
      const nextPage = page + 1;
      const hasMore = res.next ? true : false;
      this.pollStore.add(res.results);
      this.pollStore.updateState({ hasMore, nextPage });
      this.pollStore.setLoading(false);
    }

  }
  /** Retrieve latest active poll for sidebar */
  getLatestPoll() {
    return this.httpservice
      .get<Poll>(`${environment.apiBaseUri}/polls/latest`)
      .pipe(
        tap((response) => {
          this.pollStore.update({ latestPoll: response });
        })
      );
  }

  /** Add option to poll */
  addOption(id: number, option: string) {
    const body = { "option": option }
    return this.httpservice
      .post<any>(`${environment.apiBaseUri}/polls/` + id.toString() + `/options`, body)
  }

  editOption(id: number, option: string) {
    const body = { "option": option }
    return this.httpservice
      .put<any>(`${environment.apiBaseUri}/polls/options/` + id.toString(), body)
  }

  /** Remove option from poll */
  deleteOption(id: number) {
    return this.httpservice
      .delete<any>(`${environment.apiBaseUri}/polls/options/` + id.toString())
  }

  /** Vote on poll option */
  vote(option: number) {
    const body = { "poll_option": { "id": option } }
    return this.httpservice
      .post<number>(`${environment.apiBaseUri}/polls/latest/vote`, body)
  }

  /** Check whether user can vote on a poll */
  canVote() {
    return this.httpservice
      .get<boolean>(`${environment.apiBaseUri}/polls/latest/can-vote`)
      .pipe(
        tap((response) => {
          this.pollStore.update({ canVoteLatest: response })
        })
      )
  }

}
