import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { NgEntityService, NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { ApiResponse } from 'src/app/shared-resources/api-response';
import { UserState } from './user.store';
import { UserStore } from './user.store';

@NgEntityServiceConfig({
  resourceName: 'users',
})
@Injectable({ providedIn: "root" })
export class UserService extends NgEntityService<UserState> {

  constructor(protected store: UserStore) {
    super(store); // super just make this service do all the stuff that the parent component does 
  }

  @transaction()
  updateState(page: number, res: ApiResponse) {
    if(res.results){
      const nextPage = page + 1;
      const hasMore = res.next ? true : false;
      this.store.add(res.results);
      this.store.update({ hasMore, nextPage });
      this.store.setLoading(false);
    }
  }
}

