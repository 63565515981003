<esr-hero-image-banner class="is-hidden-mobile"></esr-hero-image-banner>

<section class="section">
  <div class="container">
    <esr-navigation></esr-navigation>

    <div class="columns">
      <div class="column">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</section>

<esr-footer></esr-footer>
