<div class="card">
  <header class="card-header">
    <p class="card-header-title">Links</p>
  </header>
  <div class="card-content">
    <div class="content">
      <div class="columns is-multiline" *ngIf="links$ | async as links">
        <div *ngFor="let link of links" class="column is-6 is-flex">
          <a href="{{ link.url }}" rel="noopener" target="_blank" style="width: 100%;">
            <div style="min-height: 3em; height: 100%;">
                <div class="link-item" >
                  <ng-container>
                    <img *ngIf="link.image_url; else image" class="link-icon" src="{{ mediaBaseUrl + link.image_url }}" />
                    <ng-template #image>
                      <fa-icon *ngIf="link.icon" class="link-icon" [icon]="['fas', link.icon]"></fa-icon>
                    </ng-template>
                  </ng-container>
                  <span style="white-space: normal;">{{ link.name }}</span>
                </div>
            </div>
          </a>
          <h2 *ngIf="lustrum"></h2>
        </div>
      </div>
    </div>
  </div>
</div>
<h2 *ngIf="lustrum"></h2>
