<footer class="footer">
  <div class="level">
    <div class="level-item">
      <ul>
        <li><strong>E.S.R. Thêta</strong></li>
        <li>Kanaaldijk Zuid 50</li>
        <li>5645 TA Eindhoven</li>
      </ul>
    </div>
    <div class="level-item">
      <ul>
        <li><strong>Postadres</strong></li>
        <li>Postbus 817</li>
        <li>5600 AV Eindhoven</li>
      </ul>
    </div>
    <div class="level-item">
      <ul>
        <li><strong>T: </strong>040-2438853</li>
        <li>
          <strong>E: </strong
          ><a href="mailto:bestuur@esrtheta.nl">bestuur@esrtheta.nl</a>
        </li>
        <li><strong>IBAN: </strong>NL04 ABNA 0570 9784 75</li>
      </ul>
    </div>
    <div class="level-item">
      <ul>
        <li>
          <strong>Privacyverklaring: </strong
          ><a
            href="../../assets/Privacybeleid E.S.R. Thêta Nederlands.pdf"
            target="_blank"
            >NL</a
          >,
          <a
            href= '../../assets/Privacy Policy E.S.R. Thêta English.pdf'
            target="_blank"
            >EN</a
          >
        </li>
        <li>
          <strong>Sociale Veiligheid: </strong
          ><a
            target="_blank"
            href="https://knrb.nl/veiligheid/sociale-veiligheid/"
            >Conform KNRB</a
          >
        </li>
        <li>
          <a href="https://twitter.com/esrtheta" 
          rel="noopener noreferer"
          target="_blank"
            ><fa-icon [icon]="['fab','twitter']"></fa-icon>
          </a>
          <a href="https://www.facebook.com/esrtheta" 
          rel="noopener noreferer"
          target="_blank"
            ><fa-icon [icon]="['fab','facebook-f']"></fa-icon>
          </a>
          <a
            href="https://www.instagram.com/esr.theta/"
            rel="noopener noreferer"
            target="_blank"
            ><fa-icon [icon]="['fab','instagram']"></fa-icon>
          </a>
          <a
            href="https://www.youtube.com/user/EsrTheta1"
            rel="noopener noreferer"
            target="_blank"
            ><fa-icon [icon]="['fab','youtube']"></fa-icon>
          </a>
          <a
          href="https://www.linkedin.com/company/e-s-r--th%C3%AAta/"
          rel="noopener noreferer"
          target="_blank"
          ><fa-icon [icon]="['fab','linkedin']"></fa-icon>
        </a>
        </li>
      </ul>
    </div>
  </div>
</footer>
<h2 *ngIf="lustrum"></h2>
