<div class="card">
  <div class="card-content">
    <div
      *ngFor="let tierSponsors of activeSponsorsPerTier$ | async; let j = index"
      class="sponsor"
      [ngClass]="['gold', 'silver', 'bronze'][j]"
    >
      <div *ngFor="let sponsor of tierSponsors; let i = index">
        <div
          [style.display]="loopIndexer % tierSponsors.length === i ? null : 'none'"
          class="level is-mobile"
        >
          <a target="_blank" [href]="sponsor.url" class="level-item">
            <img
              [src]="baseUrl + sponsor.logo_url"
              class="sponsorlogo"
              [alt]="sponsor.name"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
