import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { NgEntityService, NgEntityServiceConfig } from '@datorama/akita-ng-entity-service';
import { HeroImageState, HeroImageStore } from './hero-image.store';
import { HeroImage } from './hero-image.model';
// import { CounterState, CounterStore } from './counter.store';

@Injectable({ providedIn: "root" })
@NgEntityServiceConfig({
  resourceName: "heroimages",
})
export class HeroImageService extends NgEntityService<HeroImageState>{

  constructor(protected heroImageStore: HeroImageStore) {
    super(heroImageStore);
  }

  @transaction()
updateState(res: HeroImage[]) {
    if (res) {
      this.store.add(res);
      this.store.setLoading(false);
    }

  }

}
