import { Injectable } from "@angular/core";
import { PhotoAlbumsState, PhotoAlbumsStore } from "./store";
import { transaction } from "@datorama/akita";
import { ApiResponse } from "../shared-resources/api-response";
import {
  NgEntityService,
  NgEntityServiceConfig,
} from "@datorama/akita-ng-entity-service";

/**
 * PhotoAlbums Service extends the NgEntityService from Akita.
 * By extending NgEntityService, we get a several built-in
 * API calls without the need to add them ourselves: get(), add(), update() and delete().
 * https://datorama.github.io/akita/docs/angular/entity-service/
 */
@NgEntityServiceConfig({
  resourceName: "photoalbums",
})
@Injectable({ providedIn: "root" })
export class PhotoAlbumsService extends NgEntityService<PhotoAlbumsState> {
  /** @ignore */
  constructor(protected store: PhotoAlbumsStore) {
    super(store);
  }

  // /**
  //  * Transactions are an optimization for performing multiple operations on the store.
  //  * See Akita documentation: https://datorama.github.io/akita/docs/transactions/
  //  * @param page The current page number
  //  * @param res The response from the backend
  //  */
  @transaction()
  updatePhotoAlbumsStore(page: number, res: ApiResponse) {
    const nextPage = page + 1;
    const hasMore = res.next ? true : false;
    this.store.add(res.results);
    this.store.updateState({ hasMore, nextPage });
    this.store.setLoading(false);
  }

  getPhotoAlbum(res: any) {
    this.store.add(res);
  }

  updatePhotoAlbum(id: any, res: any) {
    this.store.update(id, () => {
      return {
        ...res
      }
    })
  }

  addPhotoAlbum(res: any) {
    this.store.add(res);
    // Have to update this way to get photos to load, otherwise photos array will not be pushed
    this.store.update(res.id, () => {
      return {
        ...res
      }
    })
  }
}
