import { User } from 'src/app/login-module/auth/state';

export interface Poll {
  id: number,
  pub_date: string,
  question: string,
  author: User,
  date_edited: string,
  options: Array<any>
}

