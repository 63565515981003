import { Query } from "@datorama/akita";
import { UserSettingsState, UserSettingsStore } from "./store";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

/**
 * A Query is a class offering functionality responsible for querying the store.
 * You can think of the query as being similar to database queries.
 * Its constructor function receives as parameters its own store and possibly other query classes.
 */
@Injectable({ providedIn: "root" })
export class UserSettingsQuery extends Query<UserSettingsState> {
  /** Boolean observable, if the user has seen the frontloader */
  userHasSeenFrontloader$: Observable<boolean> = this.select(
    (state) => state.userHasSeenFrontloader
  );
  /** Boolean observable, if the user has seen the user settings */
  userHasSeenSettings$ = this.select((state) => state.userHasSeenSettings);

  /** Boolean observable, if the user wants the dark-mode */
  userWantsDarkMode$ = this.select((state) => state.userWantsDarkMode);

  /** Boolean observable, if user changed dark-mode */
  userChangedDarkMode$ = this.select((state) => state.userChangedDarkMode);


  /** Boolean, if the user has seen the user settings */
  userHasSeenSettings(): boolean {
    return this.getValue().userHasSeenSettings;
  } 

 

  /** @ignore */
  constructor(protected store: UserSettingsStore) {
    super(store);
  }
}
