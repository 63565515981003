import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import { Role } from "../role.enum";
import { AuthState, AuthStore } from "./auth.store";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { AuthService } from ".";

interface ApiToken extends JwtPayload {
  claims: Role[]
  // whatever else is in the JWT.
}

/**
 * Authquery fetches the authorization token from the AuthStore.
 * It extends the Akita QueryEntity, which has additional functionality for the Akita EntityStore.
 */
@Injectable({ providedIn: "root" })
export class AuthQuery extends Query<AuthState> {
  /** 
   * Observable boolean, used to check if the user is logged in
   * 
   * Purposefully does not check for token expiry, as the app checks 
   * this on requests, the UI should not change without user interaction
   * (which would happen if the site is open on background and the token
   * expires without user interaction)
   */
  isLoggedIn$ = this.select((state): boolean => !!state.access_token);
  /** Observable of the JWT user token */
  token$ = this.select((state) => state.access_token? jwtDecode<ApiToken>(state.access_token) : undefined);

  refreshToken$ = this.select((state) => state.refresh_token)
  
  /** Observable of the user's roles */
  roles$ = this.select((state) => {
    if(state.access_token){
      const token = jwtDecode<ApiToken>(state.access_token)
      return token.claims;
    }
    else return []
    
  });
  /** Observable used to check if the user is admin */
  isAdmin$ = this.select((state) =>{
    if(state.access_token){
      const tokenRoles = jwtDecode<ApiToken>(state.access_token).claims;
      return tokenRoles.includes(Role.Bestuur) || tokenRoles.includes(Role.Admin) || tokenRoles.includes(Role.Photographer) || tokenRoles.includes(Role.Acquisicie) || tokenRoles.includes(Role.Fancie)
    }
    else return false;
  });
   /** Observable of the user object */
   user$ = this.select((state) => state.user);

   /** Returns the user data  */
   getUser() {
     return this.getValue().user;
   }
  /** Returns true boolean if the user has valid token  */
  isLoggedIn(): boolean {
    return !!this.getValue().access_token
  }
  
  /** Return the JWT token */
  getToken(): ApiToken {
    return jwtDecode<ApiToken>(this.getValue().access_token);
  }

  /** Return the JWT token */
  getRefreshToken(): string {
    return this.getValue().refresh_token;
  }

  getTokenString(): string{
    return this.getValue().access_token;
  }

  /** Return user roles */
  getRoles() {
    if(this.getValue().access_token){
      const token  =jwtDecode<ApiToken>(this.getValue().access_token);
      
      return token.claims;
    }
    else return [];
    
  }

  /** @ignore */
  constructor(protected store: AuthStore, private service: AuthService) {
    super(store);
  }
}
