import { Component } from "@angular/core";
import { News, NewsQuery, NewsService } from "../state/";
import { environment } from "../../../environments/environment";
import { NgEntityServiceLoader } from "@datorama/akita-ng-entity-service";
import { map } from "rxjs/operators";
import { ApiResponse } from "src/app/shared-resources/api-response";
import { TruncatePipe } from "../../pipe/truncate.pipe";
import { SpinnerComponent } from "../../spinner/spinner.component";
import { MarkdownModule } from "ngx-markdown";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { RouterLink } from "@angular/router";
import { ContentComponent } from "../../content/content.component";
import { NgFor, NgIf, AsyncPipe } from "@angular/common";

/**
 * NewsComponent fetches the news items from the NewsStore via the NewsQuery.
 * The NewsComponent is responsible for fetching and displaying all current News items.
 */
@Component({
    selector: "esr-news",
    templateUrl: "./news.component.html",
    styleUrls: ["./news.component.scss"],
    standalone: true,
    imports: [NgFor, ContentComponent, RouterLink, NgIf, FontAwesomeModule, MarkdownModule, SpinnerComponent, AsyncPipe, TruncatePipe]
})
export class NewsComponent {
  /** New items observable so that the html file can loop over all news items.
   * We also add the mediaBaseURI to the photo_url to simplify things in the html
   */
  public newsItems$ = this.query.items$.pipe(
    map((newsItems: News[]) => {
      return newsItems.map((item) => {
        const newItem = { ...item };
        const image = item.photo_url
          ? environment.mediaBaseURI + item.photo_url
          : "";
        newItem.photo_url = image;

        return newItem;
      });
    })
  );
  /**
   * Is true if there is a next page to fetch.
   * Used to toggle the more footer in the html file.
   */
  public hasNextPage = this.query.getHasMore();
  /** The loaders object contains loading indicators for each of the NewsService methods */
  loaders = this.loader.loadersFor("news");

  /** @ignore */
  constructor(
    private query: NewsQuery,
    private service: NewsService,
    private loader: NgEntityServiceLoader
  ) {}

  /**
   * If there is a next page, it calls the service to fetch the next page.
   */
  getNextPage() {
    this.hasNextPage = this.query.getHasMore();

    if (this.hasNextPage) {
      const nextPage = this.query.getPage();
      this.service
        .get({
          params: { page: nextPage },
          mapResponseFn: (res: ApiResponse) => this.service.updateState(nextPage, res),
        })
        .subscribe();
    }
  }
}
