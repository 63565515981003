import { Injectable } from "@angular/core";
import { QueryEntity } from "@datorama/akita";
import { map, switchMap } from "rxjs";
import { AnnouncementStore, AnnouncementState } from "./announcement.store";
import { RouterQuery } from "@datorama/akita-ng-router-store";
import { Announcement } from ".";

@Injectable({ providedIn: "root" })
export class AnnouncementQuery extends QueryEntity<
  AnnouncementState,
  Announcement
> {
  public items$ = this.selectAll()

  /**
   * Observable of item selected by route id parameter.
   */
  public item$ = this.routerQuery
    .selectParams("id")
    .pipe(
      map((i) => {
        if (i) return i;
      })
    )
    .pipe(switchMap((i) => this.selectEntity(i)));

  constructor(
    private routerQuery: RouterQuery,
    protected store: AnnouncementStore
  ) {
    super(store);
  }
}
