<div id="member-navbar" *ngIf="isLoggedIn$ | async; else spacer">
  <!-- Desktop View -->
  <nav class="navbar is-hidden-touch" role="navigation" aria-label="sub navigation">
    <div id="navMenu" class="navbar-menu">
      <!-- Navbar left -->
      <div class="navbar-start">
        <div *ngFor="let page of memberPageSlugs" class="navbar-item p-0">

          <!-- Has sub pages -->
          <div *ngIf="hasSubPages(page); else noSubPages">
            <div id="{{ page }}" class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-link is-arrowless" routerLink="/leden/{{ page }}">
                {{ getPageName(page) }}
              </a>
              <div class="navbar-dropdown">
                <div id="{{ page }}-{{ subPage}}" *ngFor="let subPage of getSubPages(page)">
                  <a class="navbar-item" routerLink="/leden/{{ page }}/{{ subPage }}">
                    {{ getPageName(subPage, page) }}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <!-- Has no sub pages -->
          <ng-template #noSubPages>
            <a class="navbar-link is-arrowless" routerLink="/leden/{{ page }}">
              <ng-container *ngIf="unreadSpams$ | async as unreadSpams">
                <ng-container *ngIf="lustrum">
                  <span *ngIf="getPageName(page) === 'Spammen' && unreadSpams > 0" title="Badge" class="badge is-top-right"
                    style='box-shadow: none;transform: translate(-10%, 10%);color:#fff4e2;background-color:#9b1b1f;'>{{unreadSpams}}</span>
                </ng-container>
                <ng-container *ngIf="!lustrum">
                  <span *ngIf="getPageName(page) === 'Spammen' && unreadSpams > 0" title="Badge" class="badge is-top-right is-primary"
                    style='box-shadow: none;transform: translate(-10%, 10%);color:black;'>{{unreadSpams}}</span>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="unreadFrusts$ | async as unreadFrusts">
                <ng-container *ngIf="lustrum">
                  <span *ngIf="getPageName(page) === 'Frusten' && unreadFrusts > 0" title="Badge" class="badge is-top-right"
                    style='box-shadow: none;transform: translate(-10%, 10%);color:#fff4e2;background-color:#9b1b1f;'>{{unreadFrusts}}</span>
                </ng-container>
                <ng-container *ngIf="!lustrum">
                  <span *ngIf="getPageName(page) === 'Frusten' && unreadFrusts > 0" title="Badge" class="badge is-top-right is-primary"
                    style='box-shadow: none;transform: translate(-10%, 10%);color:black;'>{{unreadFrusts}}</span>
                </ng-container>
              </ng-container>
              {{ getPageName(page) }}
            </a>
          </ng-template>

        </div>
        <!-- hardcoded vacatures as it does not combine correctly with the page slugs (public page in member navbar) -->
        <div class="navbar-item p-0">
          <a class="navbar-link is-arrowless" routerLink="/publiek/zakelijk/vacatures/">
            Vacatures
          </a>
        </div>
      </div>

      <!-- Navbar right -->
      <div class="navbar-end">

        <!-- Beheer Button -->
        <a id="beheer-menu" *ngIf="isAdmin$ | async" class="navbar-item" routerLink="/leden/beheer">
          Beheer
        </a>

        <!-- Profile Button -->
        <div class="navbar-item has-dropdown is-hoverable" *ngIf="user$ | async as user">
          <a id="user-button" class="navbar-link is-arrowless">
            <div id="avatar" style="position: relative; top: 2px; left: 6px">
              <figure class="image is-32x32">
                <img *ngIf="user.photo_url" src="{{baseUrl}}{{user.photo_url}}" alt="Image not found"
                  class="is-rounded"
                  onerror="this.onerror=null; this.src='./assets/blank-profile-picture.png';"
                  style="object-fit: cover; height: 100%; max-height: 100%;" />
              </figure>
            </div>
            {{ user.name }}
          </a>
          <div class="navbar-dropdown">
            <div>
              <a class="navbar-item" routerLink="/leden/profiel/{{user.id}}">
                Mijn Profiel
              </a>
            </div>
            <div>
              <a id="logout-button" class="navbar-item" (click)="logout()"> Logout </a>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>

<!-- a spacer with the same height as the member navbar. For background image aligment -->
<ng-template #spacer>
  <!-- navbar is 3.25rem, margin top 0.6rem -->
  <div style="height: 3.85rem;"></div>
</ng-template>