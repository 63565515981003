import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { RouterQuery } from "@datorama/akita-ng-router-store";
import { map, switchMap } from "rxjs/operators";
import { Role } from 'src/app/login-module/auth/role.enum';
import { UserState, UserStore } from './user.store';
import { UserService } from './user.service';

/**
 * A Query is a class offering functionality responsible for querying the store.
 * You can think of the query as being similar to database queries.
 * Its constructor function receives as parameters its own store and possibly other query classes.
 */

@Injectable({ providedIn: "root" })
export class UserQuery extends QueryEntity<UserState> {
    
  /** Returns a boolean whether there is a next page to fetch. */
    getHasMore() {
      return this.getValue().hasMore;
    }
  
    /** Returns the next page number to fetch. */
    getPage() {
      return this.getValue().nextPage;
    }


  public items$ = this.selectAll()

  public lidusers$ = this.selectAll({filterBy: (user) =>{ return user.roles.includes(Role.Member)}})

  public serusers$ = this.selectAll({filterBy: (user) =>{ return user.roles.includes(Role.SerMember)}})

  public item$ = this.routerQuery
    .selectParams("id")
    .pipe(
      map((i) => {
        if (i) return i;
      })
    )
    .pipe(switchMap((i) => this.selectEntity(i)));

  constructor(
    private routerQuery: RouterQuery,
    protected store: UserStore,
    protected service: UserService
    ) {
    super(store);
  }

  searchUsers(term: string){
    this.store.reset()
    // get members and aspiranten, 'or' search is not available on backend
    this.service
      .get({
        params: { filter_name_ilike: term, filter_is_member_equals: true, page_size: 10 },
        mapResponseFn: (res: any) => {
          this.service.updateState(1, res);
        },
      })
      .subscribe();

      this.service
      .get({
        params: { filter_name_ilike: term, filter_is_aspirant_equals: true, page_size: 10 },
        mapResponseFn: (res: any) => {
          this.service.updateState(1, res);
        },
        
      })
      .subscribe();
  }
}

