<div class="card">
  <div class="card-content content">
    <h1 class="title">Pagina niet gevonden / Page not found!</h1>

    <p>
      De opgevraagde pagina kon niet worden gevonden. Ga
      <a routerLink="/">terug naar de homepage</a> en probeer het opnieuw.
    </p>

    <p>
      The requested page could not be found. Go
      <a routerLink="/">back to the homepage</a> and try again.
    </p>
  </div>
</div>
