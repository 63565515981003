
/** @ignore */
export interface Counter {
  counter_description: string,
  counter_title: string,
  counter_url: string | undefined,
  days?: number,
  enabled: boolean,
  id?: number,
  target_date: string
}