import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { PhotoAlbumsService } from "./service";
import { PhotoAlbumsQuery } from "./query";
import { NavigationQuery, NavigationService, NavigationStore } from "../navigation/state";
import { NgEntityServiceLoader } from "@datorama/akita-ng-entity-service";
import { PhotoAlbumsStore } from "./store";
import { PhotoAlbum } from "./photo-album";
import { ApiResponse } from "../shared-resources/api-response";

/**
 * A resolver is the intermediate code, which can be executed
 * when a link has been clicked and before a component is loaded.
 * Here we use the resolver to already start fetching the PhotoAlbums data
 * before the component is loaded.
 */
@Injectable({ providedIn: "root" })
export class PhotoAlbumsResolver {
  /** @ignore */
  constructor(
    private photoAlbumsQuery: PhotoAlbumsQuery,
    private photoAlbumsService: PhotoAlbumsService,
    private photoAlbumsStore: PhotoAlbumsStore,
    private navigationService: NavigationService,
    private navigationQuery: NavigationQuery,
    private navigationStore: NavigationStore
  ) {}

  /** @ignore */
  resolve(route: ActivatedRouteSnapshot): any {
    // if id in route, get single album and remove ID from unreadAlbumIDs (if it was an unread album)
    if (route.paramMap.get("id")){
      // remove ID from navigationStore if it is in the unreadAlbumIDs
      this.navigationStore.update({
        unreadAlbumIDs: this.navigationStore.getValue().unreadAlbumIDs.filter(id => id !== parseInt(route.paramMap.get("id") || '-1')),
        unreadAlbums: this.navigationStore.getValue().unreadAlbums - 1
      })
      // return observable of album
      return this.photoAlbumsService.get(
        route.paramMap.get("id")
      );
    }
    // if no id in route, get all albums. Also update the unreadAlbumIDs in the navigationStore
    const nextPage = this.photoAlbumsQuery.getPage();
    if (nextPage === 1){
      // get the unread albums from the navigation store and if there are any fetch those and store them
      let unreadAlbums = this.navigationQuery.getValue().unreadAlbums
      if(unreadAlbums > 0){
        // if more than 0, fetch that amount of albums, sorted on date edited
        // add the albums to the store, and save the IDs in the unreadAlbumIDs of the store
        // IDs are used to highlight new albums in the list
        this.photoAlbumsService
          .get({
            params: { order_by: "date_edited", page_size: unreadAlbums },
            mapResponseFn: (res: ApiResponse) => {
              // update store with the latest retrieved albums
              this.photoAlbumsStore.add(res.results)
              // update navigationstore with the IDs of the retrievd albums
              this.navigationStore.update({
                unreadAlbumIDs: res.results.map((a) => (a as PhotoAlbum).id as number)
                })
            },
          }).subscribe();
      }
      return this.photoAlbumsService.get({
        params: { page: nextPage, page_size: 12 },
        mapResponseFn: (res: any) =>
          this.photoAlbumsService.updatePhotoAlbumsStore(nextPage, res),
      });
    }
    return;
  }
}
