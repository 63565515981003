<div *ngIf="newsItem$ | async as item">
  <esr-content image="{{ item.photo_url }}">
    <!-- Title and Pins -->
    <div class="columns is-mobile">
      <div class="column is-9">
        <div class="title">{{ item.title }}</div>
        <div class="subtitle">{{ item.subtitle }}</div>
      </div>
      <div class="column is-3">
        <span *ngIf="item.on_top" class="is-size-4 is-pulled-right">
          <fa-icon id="pinned" [icon]="['fas','bookmark']"></fa-icon>
        </span>
        <span *ngIf="item.boardmessage" class="mr-2 is-size-4 is-pulled-right">
          <fa-icon id="boardMessage" [icon]="['fas','bell']"></fa-icon>
        </span>
      </div>
    </div>

    <!-- Contents -->
    <markdown class="content" [data]="item.content"></markdown>

    <!-- Attachments -->
    <a
      class="button mt-4"
      *ngIf="item.attachment_url"
      href="{{ item.attachment_url }}"
      >Bijlage<fa-icon
        class="ml-2 has-text-grey"
        id="link-icon"
        [icon]="['fas','download']"
      ></fa-icon
    ></a>

    <!-- Author, Pub-date -->
    <div class="mt-6 is-size-7">
      Geplaatst door {{ item.author?.name }} op
      {{ item.pub_date | date: "dd-MM-yyyy" }}
      <span *ngIf="item.on_behalf"> namens {{ item.on_behalf }}</span>
    </div>
  </esr-content>

  <!-- Comments -->
  <div *ngIf="item.comments">
    <div *ngFor="let comment of item.comments" class="mt-4">
      <esr-news-comment [comment]="comment"></esr-news-comment>
    </div>
  </div>

  <div *ngIf="roles$| async as roles">
    <div class="box mt-4" *ngIf="roles.includes(Role.Member) || roles.includes(Role.Admin)">
      <form [formGroup]="commentForm" (ngSubmit)="submit()">
        <textarea
          id="news-comment-text-input"
          (onEnter)="(submit)"
          class="textarea is-brown"
          rows="2"
          type="text"
          formControlName="chatText"
        ></textarea>
        <div class="level py-1">
          <div class="level-left"></div>
          <div class="level-right">
            <button class="button is-primary" type="submit" [disabled] = "commentForm.invalid" >Reageer!</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
