<div class="news-comment">
  <div class="box">
    <div class="columns">
      <!-- Profile Photo -->
      <div class="column">
        <figure class="image is-64x64" style="height: 64px">
          <a routerLink="/leden/profiel/{{ comment.author.id }}">
            <img src="{{baseUrl}}{{ comment.author.photo_url }}"
              alt="Profile Picture" class="is-rounded"
              onerror="this.onerror=null; this.src='./assets/blank-profile-picture.png';"
              style="height:100%; width:100%; object-fit: cover" />
          </a>
        </figure>
      </div>
      <div class="column is-11">
        <div class="mb-3">
          <a routerLink="/leden/profiel/{{ comment.author.id }}">
            <span class="title is-6">{{ comment.author.name }}</span>
          </a>
          <span> | {{ comment.pub_date | date: "dd-MM-yyyy HH:mm" }} </span>
          <span *ngIf="user$ | async as user">
            <button *ngIf="user.roles.includes(Role.Admin) || user.roles.includes(Role.Bestuur)" class="delete" (click)="deleteComment(comment.id)"></button>
          </span>
        </div>
        <div [innerHTML]="comment.text"></div>
    </div>
  </div>
</div>