import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable, isDevMode } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError } from "rxjs";
import { environment } from "src/environments/environment";

import { AuthQuery, AuthService } from "./login-module/auth/state";
/**
 * The purpose of this interceptor is two-fold. It makes sure outgoing requests
 * use appropriate URIs and that the correct Authorization headers are set.
 *
 * It is necessary that all requests to the API contain a trailing slash. When
 * one is not present a 308 redirect is returned to the URL with a trailing
 * slash, without CORS headers causing the request to fail.
 *
 * This interceptor ensures a trailing slash is always added to any calls to
 * the API. This is implemented as an interceptor facilitating, for instance,
 * the use of Akita's NgEntityService which doesn't otherwise have any
 * capabilities of adding a trailing slash.
 *
 * Responses from the API for collections are paginated. These contain
 * additional information that gets in the way of consumption by
 * NgEntityService derived services. To mitigate this problem, when fetching
 * data with the NgEntityService a mapResponseFn has to be passed to handle
 * the response
 * 
 * The authorization token can be expired and in need of refreshing,
 * token refresh code is adapted from the blog:
 * https://www.bezkoder.com/angular-12-refresh-token/
 * 
 */
@Injectable()
export class ApiInteractionNormalizer implements HttpInterceptor {
  /** @ignore */
  constructor(private authQuery: AuthQuery, private service: AuthService, private toastr: ToastrService) { }
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  /**
   * Intercept any and all http requests within our app 
   * add trailing slash to use always correct URI
   * Add authentication header and if needed refresh auth token
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let req = this.ensureTrailingSlash(request);

    if (this.authQuery.isLoggedIn() && request.url.includes(environment.apiBaseUri)) {
      // add token if request is made to api, and token exists
      req = this.addAuthTokenToHeader(req, this.authQuery.getTokenString())
    }

    return next.handle(req).pipe(catchError(error => {
      const token = this.authQuery.getToken()
      if ((error instanceof HttpErrorResponse && req.url.startsWith(environment.apiBaseUri)) && 
          ((!req.url.includes('login') && error.status === 401 && JSON.stringify(error.error).includes("Token has expired"))
          || (token && token.exp && token.exp < (Date.now() / 1000 | 0)))) {
        // refresh token before returning request IF 
        // - request to API (startswith baseUri) AND
        //  - 401 error on  with message "Token has expired"
        //  - if token has exired (should not be needed as backend should return either exception, but just in case
        //    backend ever would return a different error code)
        return this.handleExpiryToken(req, next);
      }

      if(error.status === 422 && JSON.stringify(error.error).includes("Signature verification failed")){
        // if signature verification fails, the token is no longer valid and app should be logged out
        // this is usefull for development, when a token from another environment might
        // be in the store, while interacting with development, local or production.
        if(isDevMode()){
          // show error when in dev mode to maybe finally find the error :(
            this.toastr.error(JSON.stringify(error.error), 'Error in refresh')
        }
        this.service.logout()
      }

      throw error;
    }));
  }

  /** 
   * Handle token refreshing on error in request
   */
  private handleExpiryToken(request: HttpRequest<any>, next: HttpHandler) {
    // This checks if the token is already refreshing (!this.isrefreshing)
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const token = this.authQuery.getRefreshToken();

      if (token)
        return this.service.refresh(token).pipe(
          switchMap((token) => {
            // new token has been retrieved by refresh method in the authService
            this.isRefreshing = false;
            // add token to refreshsubject, this is used for all requests coming in
            // during the refresh request
            this.refreshTokenSubject.next(token.access_token);
            // return request with new token
            return next.handle(this.addAuthTokenToHeader(request, token.access_token));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            if(isDevMode()){
              // show error when in dev mode to maybe finally find the error :(
                this.toastr.error(JSON.stringify(err), 'Error in refresh')
            }
            // if for any reason the refresh fails, log out user
            this.service.logout();
            return throwError(() => Error(err));
          })
        );
    }
    // when we are refreshing the token, return any request with this pipe.
    // when refreshtoken is added to the subject (line 89) it will pipe it to any
    // waiting request and execute the request
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addAuthTokenToHeader(request, token)))
    );

  }


  /** clone the request with a Auhtorization header added */
  private addAuthTokenToHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: { authorization: token },
    });
  }

  /** Add trailing slash to requests */
  private ensureTrailingSlash(request: HttpRequest<any>): HttpRequest<any> {
    // Only append a slash if it's not already present
    if (
      !request.url.endsWith("/") &&
      !request.url.includes("?") &&
      request.url.includes(environment.apiBaseUri)
    )
      // The `url` property is `readonly`, so a copy of the request needs to be
      // created to modify it
      return request.clone({
        url: `${request.url}/`,
      });
    else return request;
  }
}
