import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { User } from 'src/app/login-module/auth/state';


export interface UserState extends EntityState<User> {
  /** Boolean, used to check if there are more pages to load */
  hasMore: boolean;
  /** Number, used to keep track of the next page number */
  nextPage: number;
}

/**
 * The Store is a single object which contains the store state and serves as the "single source of truth."
 * For the most part, the stores you'll require in your applications will be entity stores.
 * You can think of an entity store as a table in a database,
 * where each table represents a flat collection of entities.
 */


@Injectable({ providedIn: "root" })
@StoreConfig({
  name: 'users'
})
export class UserStore extends EntityStore<UserState> {

  constructor() {
    super({hasMore: true, nextPage: 1});
  }
}
