import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AuthQuery } from "./state";
import { ToastrService } from "ngx-toastr";
import { Role } from "./role.enum";

/** The authguard makes sure that users cannot perform actions they are not authorized for. */
@Injectable({
  providedIn: "root",
})
export class AuthGuard  {

  /** @ignore */
  constructor(
    protected authQuery: AuthQuery,
    protected router: Router,
    private toastr: ToastrService
  ) {}

  /** CanLoad, allows for custom logic to execute before loading feature module assets */
  canLoad(): boolean | Observable<boolean> | Promise<boolean> {
    return this.checkLogin();
  }

  /** CanActivate and CanActivateChild, used for checking auth access to a route */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.checkLogin(route);
  }

  /** CanActivate and CanActivateChild, used for checking auth access to a route */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.checkLogin(childRoute);
  }

  /**
   * Checks if the current user is logged-in and authorized to view the route.
   * @param route The requested route.
   */
  protected checkLogin(route?: ActivatedRouteSnapshot): boolean {
    /** Boolean, used to check if the user's roles match the route's required roles */
    let roleMatch = true;
    /** Params, used for re-routing the user after login */
    let params: any;
    if (route && this.authQuery.getRoles().length > 0) {
      const expectedRoles: Role[] = route.data.expectedRoles;

      if (expectedRoles) {
        roleMatch = this.checkIfAnyRoleMatches(expectedRoles);
      }

      if (roleMatch) {
        params = {
          redirectUrl: route.pathFromRoot.map((r) => r.url).join("/"),
        };
      }
    }

    if (!this.authQuery.isLoggedIn() || !roleMatch) {
      this.showAlert(this.authQuery.isLoggedIn(), roleMatch);

      this.router.navigate(["/news", params || {}]);
      return false;
    }

    return true;
  }

  /** Returns true if one of the User's roles is included in the expected roles */
  private checkIfAnyRoleMatches(expectedRoles: Role[]): boolean {
    const roleMatches = expectedRoles.map((expectedRole) =>
      this.authQuery.getRoles().includes(expectedRole)
    );
    return roleMatches.some((item) => item === true);
  }

  /** Displays a toast message */
  private showAlert(isAuth: boolean, roleMatch: boolean): void {
    if (!isAuth) {
      this.toastr.warning("Je moet hiervoor ingelogd zijn!");
    } else if (!roleMatch) {
      this.toastr.warning("Je bent hiervoor niet geautoriseerd!");
    } else {
      this.toastr.error("Sorry er is iets mis gegaan!");
    }
  }
}
