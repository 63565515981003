import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { NavigationStore, NavigationState, navigationStore } from './navigation.store';

@Injectable({ providedIn: "root" })
export class NavigationQuery extends Query<NavigationState> {

  constructor(protected store: NavigationStore) {
    super(store);
  }

  public unreadItems$ = this.select();

}

export const navigationQuery = new NavigationQuery(navigationStore);
