<!-- <div class="sidebar-item is-hidden-mobile"  *ngIf="(isMember$ | async) === false">
  <div class="card has-background-primary" *ngIf="!lustrum">
    <a href="https://intro.esrtheta.nl/" class="card-header">
      <div class="m-5">
        <p class="title is-4">Lid worden?</p>
        <p class="title is-5">Klik hier om naar de introductie site te gaan!</p>
      </div>
    </a>
  </div>

  <div class="card" *ngIf="lustrum" style="background-color: #9b1b1f;">
    <a href="https://intro.esrtheta.nl/" class="card-header">
      <div class="m-5">
        <p class="title is-4" style="color: #fff4e2;">Lid worden?</p>
        <p class="title is-5" style="color: #fff4e2;">Klik hier om naar de introductie site te gaan!</p>
      </div>
    </a>
  </div>

</div> -->
<div id="announcements">
  <esr-announcement
    id="desktop-announcements"
    class="is-hidden-touch"
  ></esr-announcement>
</div>
<div class="sidebar-item">
  <esr-sponsor></esr-sponsor>
</div>
<div class="sidebar-item">
  <esr-links></esr-links>
</div>

<div id="counter">
  <esr-counter></esr-counter>
</div>

<div id="sidebar-item" *ngIf="isMember$ | async">
  <esr-poll></esr-poll>
</div>

<div class="sidebar-item">
  <esr-google-calendar></esr-google-calendar>
</div>


