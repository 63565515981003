import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AuthQuery } from 'src/app/login-module/auth/state';
import { Poll, PollQuery, PollService } from './state';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'esr-poll', 
    templateUrl: './poll.component.html',
    styleUrls: ['./poll.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, AsyncPipe]
})
export class PollComponent {
  /** Boolean observable to toggle the poll component */
  public isLoggedIn$: Observable<boolean> = this.authQuery.isLoggedIn$;

  
  public lustrum = false;

  /** Latest active poll observable, used for displaying in the html */
  public latestPoll$: Observable<Poll> = this.query.latestPoll$;

  /** Boolean keeping track whether a user has voted yet */
  public canVote$: Observable<boolean> = this.query.canVote$;

  /** Poll form */
  public pollForm = new FormGroup({
    option: new FormControl<number | undefined>(undefined, {nonNullable: true, validators: Validators.required})
  });

  constructor(
    private authQuery: AuthQuery, 
    private service: PollService, 
    private query: PollQuery, 
    private toastr: ToastrService,
    private router: Router) {

      router.events.subscribe(val => {
        if (this.router.url.includes("lustrum")) {
          this.lustrum = true;
        } else {
          this.lustrum = false;
        }
      })
      this.authQuery.isLoggedIn$.subscribe({
        next: (res) => {
          if (res) {
            this.updateLatestPoll()
          }
        }
      })
  }



  updateLatestPoll() {
    this.service.getLatestPoll().subscribe()
    this.service.canVote().subscribe()
  }

  /** Vote on poll */
  submit() {
    /** Check whether an option was selected */
    if (this.pollForm.valid && this.pollForm.value.option) {
      this.service.vote(this.pollForm.value.option).subscribe({
        next: () => {
          this.toastr.success('Je hebt gestemd!');
          // refresh poll
          this.service.getLatestPoll().subscribe();
          this.service.canVote().subscribe();
        }
      });
    }
  }

  /** Calculate total amount of votes */
  totalVotes() {
    var total: number = 0
    this.latestPoll$.subscribe((poll) => {
      poll.options.forEach(function (item) {
        total += item.no_of_votes;
      })
    })
    return total;
  }


}
