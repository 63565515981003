import { Component } from "@angular/core";
import { DatePipe, NgIf, NgFor, AsyncPipe } from '@angular/common'
import { CounterQuery, CounterService } from "./state";
import { ApiResponse } from "../shared-resources/api-response";
import { AuthQuery } from "../login-module/auth/state";
import { Router } from "@angular/router";

@Component({
    selector: "esr-counter",
    templateUrl: "./counter.component.html",
    styleUrls: ["./counter.component.scss"],
    providers: [DatePipe],
    standalone: true,
    imports: [NgIf, NgFor, AsyncPipe]
})
export class CounterComponent {

    /** Counter Observable */
    public counter$ = this.query.counters$;

    public lustrum = false;
    /** Counter constructor */
    /** @ignore */
    constructor(
        private query: CounterQuery,
        private service: CounterService,
        private authQuery: AuthQuery,
        private router: Router) {
        // update on load
        this.updateCounters();
        // update when user logs in/out
        this.authQuery.isLoggedIn$.subscribe({ next: () => { this.updateCounters() } })
        router.events.subscribe(val => {
            if (this.router.url.includes("lustrum")) {
              this.lustrum = true;
            } else {
              this.lustrum = false;
            }
          })

    }

    updateCounters() {
        this.service
            .get({
                mapResponseFn: (res: ApiResponse) => this.service.updateState(res),
            })
            .subscribe();
    }
}
