import { Injectable } from "@angular/core";
import { QueryEntity } from "@datorama/akita";
import { Observable } from "rxjs";
import { PhotoAlbumsStore, PhotoAlbumsState } from "./store";
import { PhotoAlbum } from "./photo-album";
import { RouterQuery } from "@datorama/akita-ng-router-store";
import { switchMap } from "rxjs/operators";

/**
 * The PhotoAlbumsQuery fetches photo albums from the PhotoAlbumsStore.
 * It extends the Akita QueryEntity, which has additional functionality for the Akita EntityStore.
 */
@Injectable({ providedIn: "root" })
export class PhotoAlbumsQuery extends QueryEntity<PhotoAlbumsState> {
  /** Observable with all photo albums currently in the PhotoAlbumsStore */
  public photoAlbums$: Observable<PhotoAlbum[]> = this.selectAll();
  /** Observable with photo album the current url route points to */
  // public photoAlbum$: Observable<PhotoAlbum>;

  public photoAlbum$ = this.routerQuery
    .selectParams("id")
    .pipe(switchMap((i) => this.selectEntity(i)));

  /** @ignore */
  constructor(protected store: PhotoAlbumsStore, protected routerQuery: RouterQuery) {
    super(store);
  }
  /** Returns a boolean whether there is a next page to fetch. */
  getHasMore(): boolean {
    return this.getValue().hasMore;
  }

  /** Returns the next page number to fetch. */
  getPage(): number {
    return this.getValue().nextPage;
  }
}
