import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Counter } from './counter.model';

export interface CounterState extends EntityState<Counter>  {
  enabled: boolean;
}

const initialState: CounterState = {
  enabled: false,
};

@Injectable({ providedIn: "root" })
@StoreConfig({ name: 'counter' })
export class CounterStore extends EntityStore<CounterState> {
  /** @ignore */
  constructor() {
    super(initialState);
  }

}

