
<div *ngIf="counter$ | async as counters">
  <div *ngFor="let counter of counters;">
    <div  *ngIf="(counter.days ?? -1) >= 0">
      <div class="card" *ngIf="counter.enabled === true">
        <header *ngIf="counter.counter_url" class="card-header" >
          <div class="column">
            <p class="card-header-title has-text-centered"> <a class="header_style" target="_blank" href="{{ counter.counter_url}} ">{{ counter.counter_title }}</a> </p>
          </div>
          <h2 *ngIf="lustrum"></h2>
        </header>
        <header *ngIf="counter.counter_url === '' " class="card-header">
          <p class="card-header-title has-text-centered">{{ counter.counter_title }}</p>
          <h2 *ngIf="lustrum"></h2>
        </header>
        <div class="card-content">
          <div class="notification has-text-centered">
            <div *ngIf="counter.days !== 0; else today">
              <p class="title is-6">Nog</p>
              <p class="title is-1">{{ counter.days }}</p>
              <p class="title is-6" *ngIf="counter.days !== 1">dagen {{ counter.counter_description }} </p>
              <p class="title is-6" *ngIf="counter.days === 1">dag {{ counter.counter_description }}</p>
            </div>
            <ng-template #today>
              <p class="title is-3">Vandaag wordt er gewonnen!</p>
            </ng-template>
          </div>
          <h2 *ngIf="lustrum"></h2>
        </div>
      </div>
      <h2 *ngIf="lustrum"></h2>
    </div>
  </div>
</div>