import { Injectable } from '@angular/core';
import { Order, QueryEntity } from '@datorama/akita';
import { map, switchMap } from 'rxjs';
import { PollStore, PollState } from './poll.store';
import { RouterQuery } from "@datorama/akita-ng-router-store";

@Injectable({ providedIn: "root" })
export class PollQuery extends QueryEntity<PollState> {

  /** Poll Observable */
  public polls$ = this.selectAll({sortBy: 'pub_date',  sortByOrder: Order.DESC});
  public poll$ = this.routerQuery
    .selectParams("id")
    .pipe(
      map((i) => {
        if (i) return i;
      })
    )
    .pipe(switchMap((i) => this.selectEntity(i)));

  latestPoll$ = this.select((state) => state.latestPoll);
  canVote$ = this.select((state) => state.canVoteLatest)

  public hasMore$ = this.select((state) => state.hasMore);
  public hasMore = this.getValue().hasMore

  constructor(private routerQuery: RouterQuery, protected store: PollStore) {
    super(store);
  }


  /** Returns the next page number to fetch. */
  getPage() {
    return this.getValue().nextPage;
  }

}
