import { Component } from "@angular/core";
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterLink, RouterOutlet } from "@angular/router";
import { SidebarComponent } from "../../sidebar/sidebar.component";
import { SpinnerComponent } from "../../spinner/spinner.component";
import { AsyncPipe, NgClass, NgIf } from "@angular/common";
import { AnnouncementComponent } from "../../announcement/announcement.component";
import { AuthQuery } from "src/app/login-module/auth/state";
import { map } from "rxjs";
import { Role } from "src/app/login-module/auth/role.enum";

/** The half page component. Used to diplay the sidebar on the right
 *  and the router content on the left.
 */
@Component({
    selector: "esr-half-page",
    templateUrl: "./half-page.component.html",
    styleUrls: ["./half-page.component.scss"],
    standalone: true,
    imports: [AnnouncementComponent, RouterLink, NgClass, NgIf, SpinnerComponent, RouterOutlet, SidebarComponent, AsyncPipe]
})
export class HalfPageComponent {
  /** `loading$` used to toggle the loading spinner */
  public lustrum = false;
  public loading = true;
  public activeTab: number = 1
  isMember$ = this.authQuery.roles$.pipe(map((roles) => roles.includes(Role.Member)));
  isLoggedIn$ = this.authQuery.isLoggedIn$;
  
  /** @ignore */
  constructor(
    private router: Router,
    private authQuery: AuthQuery) {
      router.events.subscribe(val => {
        if (this.router.url.includes("lustrum")) {
          this.lustrum = true;
        } else {
          this.lustrum = false;
        }

        if (this.router.url.includes("activiteiten")) {
          this.activeTab = 2;
        } else if (this.router.url.includes("werkacties")) {
          this.activeTab = 3;
        } else if (this.router.url.includes("reserveren")) {
          this.activeTab = 4;
        } else if (this.router.url.includes("news")) {
          this.activeTab = 1;
        } else {
          this.activeTab = 0;
        }
      })

    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationStart) {
          this.loading = true;
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.loading = false;
        }
      },
      complete: () => (this.loading = false),
    });
  }
}
