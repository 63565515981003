import { Store, StoreConfig } from "@datorama/akita";
import { Injectable } from "@angular/core";

/**
 * We need to define the store's interface by extending the EntityState from Akita
 */
export interface UserSettingsState {
  /** Boolean, if the user has seen the front loader */
  userHasSeenFrontloader: boolean;
  /** Boolean, if the user has seen the user settings */
  userHasSeenSettings: boolean;
  /** Boolean, if the user wants the dark-mode */
  userWantsDarkMode: boolean;
  /** Boolean, user changed dark-mode setting*/
  userChangedDarkMode: boolean;
}

/** Function to set the initial state of the store. */
export function createInitialState(): UserSettingsState {
  return {
    userHasSeenFrontloader: false,
    userHasSeenSettings: false,
    userWantsDarkMode: false,
    userChangedDarkMode: false,
  };
}

/**
 * The Store is a single object which contains the store state and serves as the "single source of truth."
 * For the most part, the stores you'll require in your applications will be entity stores.
 * You can think of an entity store as a table in a database,
 * where each table represents a flat collection of entities.
 */
@Injectable({ providedIn: "root" })
@StoreConfig({ name: "userSettings" })
export class UserSettingsStore extends Store<UserSettingsState> {
  /** @ignore */
  constructor() {
    super(createInitialState());

  }
}
