import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface NavigationState {
  unreadSpams: number;
  unreadFrusts: number;
  unreadAlbums: number;
  unreadAlbumIDs: number[];
}

export function createInitialState(): NavigationState {
  return {
    unreadSpams: 0,
    unreadFrusts: 0,
    unreadAlbums: 0,
    unreadAlbumIDs: [],
  };
}

@Injectable({ providedIn: "root" })
@StoreConfig({ name: 'navigation' })
export class NavigationStore extends Store<NavigationState> {

  constructor() {
    super(createInitialState());
  }

}

export const navigationStore = new NavigationStore();
