// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/**
 * Theta Google Calendar Id.
 */
const calendarId = "d53kvq1jpl6o12i2e2t2a4mpdg%40group.calendar.google.com";

/**
 * Stores configuration values for non-production builds.
 *
 * Should be included into files that need the values it defines using
 * `import { environment } from 'relative/path/to/enironments/environment';`.
 * This will automatically include the correct file based on the build
 * environment due to the configuration in `angular.json`.
 *
 * Values for production builds are stored in the corresponding
 * `environment.prod.ts` file.
 */
// export const environment = {
//   apiBaseUri: "https://web35.esrtheta.nl/v2",
//   mediaBaseURI: "https://web35.esrtheta.nl",
//   googleCalendarBaseUri: `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`,
//   googleApiKey: "AIzaSyC4DXLm_liB9Vyw-sUIZPiI1_nhdrDSpME",
//   production: false,
//   IMGUR_API_KEY: "Client-ID 415dac95b463b24",
//   calendarId,
//   mamonIntegration: true
// };

export const environment = {
  apiBaseUri: "https://web35.esrtheta.nl/v2",
  mediaBaseURI: "https://web35.esrtheta.nl",
  googleCalendarBaseUri: `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`,
  googleApiKey: "AIzaSyC4DXLm_liB9Vyw-sUIZPiI1_nhdrDSpME",
  production: false,
  IMGUR_API_KEY: "Client-ID 415dac95b463b24",
  calendarId,
  mamonIntegration: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
