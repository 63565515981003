import { Component, Input } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Role } from "src/app/login-module/auth/role.enum";
import { AuthQuery } from "src/app/login-module/auth/state";
import { environment } from "src/environments/environment";
import { Comment, NewsService } from "../state";
import { NgIf, AsyncPipe, DatePipe } from "@angular/common";
import { RouterLink } from "@angular/router";

/** NewsCommentComponent, used to display comments on newsItems */
@Component({
    selector: "esr-news-comment",
    templateUrl: "./news-comment.component.html",
    styleUrls: ["./news-comment.component.scss"],
    standalone: true,
    imports: [RouterLink, NgIf, AsyncPipe, DatePipe]
})
export class NewsCommentComponent {
  /** `comment`, the comment object to display */
  @Input() comment: Comment = {} as Comment;
  /** The api base uri, used for image src links. */
  public baseUrl = environment.mediaBaseURI;
  public user$ = this.authQuery.user$;
  public Role = Role
  constructor(
    private authQuery: AuthQuery,
    private service: NewsService,
    private toastr: ToastrService,
  ) { }

  deleteComment(commentId: number) {
    this.service.deleteComment(commentId).subscribe({
      next: () => {
        this.toastr.success("Comment verwijderd")
        window.location.reload()
      },
      error: (e: any) =>{
        this.toastr.error(e, "Error")
      }
    })
  }
}
