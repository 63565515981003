import { enableProdMode, LOCALE_ID, importProvidersFrom } from "@angular/core";
import { environment } from "./environments/environment";
import { akitaConfig, persistState } from "@datorama/akita";
import { akitaDevtools } from "@datorama/akita";
import { WebsiteComponent } from "./app/website/full-page/website.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { ToastrModule } from "ngx-toastr";
import { MarkdownModule, MarkedOptions, MarkedRenderer } from "ngx-markdown";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { AkitaNgDevtools } from "@datorama/akita-ngdevtools";
import { AkitaNgRouterStoreModule } from "@datorama/akita-ng-router-store";
import { NG_ENTITY_SERVICE_CONFIG } from "@datorama/akita-ng-entity-service";
import { ApiInteractionNormalizer } from "./app/api-interaction-normalizer.interceptor";
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient, HttpClient } from "@angular/common/http";
import { registerLocaleData } from '@angular/common';
import localeNL from '@angular/common/locales/nl';
import { provideRouter, withInMemoryScrolling, } from "@angular/router";
import routes from "./app/routes";
registerLocaleData(localeNL);

akitaDevtools();
if (environment.production) {
    enableProdMode();
}

/** The persist state from Akita, used to store the data in the local storage of the device */
const storage = persistState({
    include: ["userSettings", "auth", "user", "botenvloot"],
});
akitaConfig({ resettable: true });

/**
 * A markdown renderer to enable opening links in a new tab.
 */
export function markedOptionsFactory(): MarkedOptions {
    const renderer = new MarkedRenderer();
    const linkRenderer = renderer.link;
  
    renderer.link = (href, title, text) => {
      const html = linkRenderer.call(renderer, href, title, text);
      return html.replace(/^<a /, '<a role="link" tabindex="0" target="_blank" rel="nofollow noopener noreferrer" ');
    };
  
    // get default markedoptinos
    const markedOptions = new MarkedOptions()
    markedOptions.renderer = renderer
    return markedOptions
  }

/** The persistState() function gives you the ability to persist some of the app’s state,
 * by saving it to localStorage/sessionStorage or anything that implements the StorageEngine API,
 * and restore it after a refresh.
 */
const providers = [{ provide: "persistStorage", useValue: storage }];

bootstrapApplication(WebsiteComponent, {
    providers: [
        importProvidersFrom(
            AkitaNgRouterStoreModule,
            environment.production ? [] : AkitaNgDevtools,
            BrowserModule,
            FontAwesomeModule,
            FormsModule,
            ReactiveFormsModule,
            MarkdownModule.forRoot({
                loader: HttpClient,
                markedOptions: {
                  provide: MarkedOptions,
                  useFactory: markedOptionsFactory,
                },
            }),
            ToastrModule.forRoot(),
            ServiceWorkerModule.register("ngsw-worker.js", {
                enabled: environment.production,
            }),
        ),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInteractionNormalizer,
            multi: true,
        },
        {
            provide: NG_ENTITY_SERVICE_CONFIG,
            useValue: {
                baseUrl: environment.apiBaseUri,
            },
        },
        {
            provide: LOCALE_ID,
            useValue: "nl-NL"
        },
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        provideRouter(routes, withInMemoryScrolling({ scrollPositionRestoration: "enabled" })),

    ]
})
    .catch((err) => console.error(err));
