import { Component } from "@angular/core";
import { GoogleCalendarApiService } from "./google-calendar-api.service";
import { NgFor, AsyncPipe, SlicePipe, DatePipe, NgIf } from "@angular/common";
import { ModalComponent } from "../modal/modal.component";
import { environment } from "src/environments/environment";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { Router } from "@angular/router";

/**
 * GoogleCalendarComponent fetches the calendar events via the GoogleCalendarApiService.
 */
@Component({
    selector: "esr-google-calendar",
    templateUrl: "./google-calendar.component.html",
    styleUrls: ["./google-calendar.component.scss"],
    standalone: true,
    imports: [NgIf, NgFor, AsyncPipe, SlicePipe, DatePipe, ModalComponent, FontAwesomeModule]
})
export class GoogleCalendarComponent {
  /**
   * Number of calendar events to be displayed by the component.
   */
  public numberOfEvents = 5;

  public lustrum = false;
  /**
   * Calendar events observable.
   */
  public events$ = this.service.loadEvents();
  // add calenderid for url to add calender modal
  public calenderId = environment.calendarId
  /** @ignore */
  constructor(public service: GoogleCalendarApiService,
    private router: Router
  ) {
    router.events.subscribe(val => {
      if (this.router.url.includes("lustrum")) {
        this.lustrum = true;
      } else {
        this.lustrum = false;
      }
    })
  }

  /**
   * Increases numberOfEvents variable by 5.
   */
  showMoreEvents() {
    this.numberOfEvents += 5;
  }
}
