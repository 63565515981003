/** The user roles supported by the backend */
export enum Role {
  Aspirant = "is_aspirant",
  Member = "is_member",
  SerMember = "is_ser_member",
  Photographer = "is_photographer",
  Acquisicie = "is_acquisicie",
  Fancie = "is_fancie",
  SerBestuur = "is_ser_admin",
  Bestuur = "is_board",
  Admin = "is_superadmin",
  Medewerker = "is_employee",
  Erelid_LVV = "is_erelid_lvv",
}
