<esr-modal #addAgendaModal title="Agenda" submitString="sluiten">
  <div content class="content">
    <p>Om de agenda van de E.S.R. Thêta toe te voegen aan je eigen kalender kun je de volgende link toevoegen in je kalender.</p>

    <p>Zowel in de meeste kalenderapps als in je email/agenda-account (Microsoft, Google etc) kun je dit toevoegen.</p>
    <p>Let erop dat je de link (en niet het bestand) zelf toevoegt, anders blijft de agenda niet up to date</p>
    <pre>https://calendar.google.com/calendar/ical/{{calenderId}}/public/basic.ics</pre>
  </div>
    
</esr-modal>

<div class="card">
  <header class="card-header">
    <p class="card-header-title">Kalender</p>
    <h2 *ngIf="lustrum"></h2>
    <div class="m-1 pull-right">
      <a class="button is-text" (click)="addAgendaModal.openModal()">
        <fa-icon [icon]="['fas','link']"></fa-icon>
      </a>  
    </div>
    
  </header>
  <div class="card-content" *ngIf="!lustrum">
    <div id="event" *ngFor="let event of events$ | async | slice: 0:numberOfEvents; let i = index">
      <a href="{{ event.uri }}">
        <div class="columns is-hidden-tablet-only is-hidden-desktop-only is-mobile">
          <div id="column-right" class="column">
            <p style="word-break: break-all;">
              <strong>{{ event.title }}</strong>
            </p>
            <p id="event_dates">
              {{ event.start | date: "dd-MM-yyyy" }} -
              {{ event.end | date: "dd-MM-yyyy" }}
            </p>
          </div>
          <div id="column-left" class="column is-3">
            <strong>{{ event.start | date: "d" }}<br /></strong>
            <strong>{{ event.start | date: "MMM" }}</strong>
          </div>
        </div>

        <div class="columns is-hidden-mobile is-hidden-widescreen is-tablet-only is-desktop-only">
          <div id="column-right" class="column">
            <p style="word-break: break-all;">
              <strong>{{ event.title }}</strong>
            </p>
            <p id="event_dates">
              {{ event.start | date: "dd-MM-yyyy" }} -
              {{ event.end | date: "dd-MM-yyyy" }}
            </p>
          </div>
        </div>
      </a>
    </div>
  </div>


  <div class="card-content" *ngIf="lustrum">
    <div id="event" *ngFor="let event of events$ | async | slice: 0:numberOfEvents; let i = index">
      <a href="{{ event.uri }}">
        <div class="columns is-hidden-tablet-only is-hidden-desktop-only is-mobile">
          <div id="column-right-lustrum" class="column">
            <p style="word-break: break-all;">
              <strong>{{ event.title }}</strong>
            </p>
            <p id="event_dates">
              {{ event.start | date: "dd-MM-yyyy" }} -
              {{ event.end | date: "dd-MM-yyyy" }}
            </p>
          </div>
          <div id="column-left" class="column is-3">
            <strong>{{ event.start | date: "d" }}<br /></strong>
            <strong>{{ event.start | date: "MMM" }}</strong>
          </div>
          <h2></h2>
        </div>

        <div class="columns is-hidden-mobile is-hidden-widescreen is-tablet-only is-desktop-only">
          <div id="column-right-lustrum" class="column">
            <p style="word-break: break-all;">
              <strong>{{ event.title }}</strong>
            </p>
            <p id="event_dates">
              {{ event.start | date: "dd-MM-yyyy" }} -
              {{ event.end | date: "dd-MM-yyyy" }}
            </p>
          </div>
          <h2></h2>
        </div>
      </a>
    </div>
  </div>
  <h2 *ngIf="lustrum"></h2>

  <footer class="card-footer">
    <div class="column">
      <a class="card-footer-item" (click)="showMoreEvents()">More</a>
    </div>
    <h2 *ngIf="lustrum"></h2>
    
  </footer> 
</div>
<h2 *ngIf="lustrum"></h2>