import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { ApiResponse } from "src/app/shared-resources/api-response";

import { NewsService, NewsQuery } from "../state/";

/**
 * A resolver is the intermediate code, which can be executed
 * when a link has been clicked and before a component is loaded.
 * Here we use the resolver to already start fetching the bestuur data
 * before the component is loaded.
 */
@Injectable({ providedIn: "root" })
export class NewsResolver  {
  /** @ignore */
  constructor(private newsService: NewsService, private newsQuery: NewsQuery) {}

  /** @ignore */
  resolve(route: ActivatedRouteSnapshot): any {
    const id = route.paramMap.get("id")
    if (id)
      return this.newsService.get(id);

    const nextPage = this.newsQuery.getPage();
    if (nextPage === 1)
      return this.newsService.get({
        params: { page: nextPage },
        mapResponseFn: (res: ApiResponse) => this.newsService.updateState(nextPage, res),
      });

    return;
  }
}
