import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { HeroImageService } from "./hero-image.service";
import { HeroImageQuery } from "./hero-image.query";
import { mapResponse } from "@datorama/akita-ng-entity-service";
import { ApiResponse } from "src/app/shared-resources/api-response";

/**
 * A resolver is the intermediate code, which can be executed
 * when a link has been clicked and before a component is loaded.
 * Here we use the resolver to already start fetching the counter data
 * before the component is loaded.
 */
@Injectable({ providedIn: "root" })
export class HeroImagesResolver  {
  /** @ignore */
  constructor(private service: HeroImageService) {}

  /** @ignore */
  resolve(route: ActivatedRouteSnapshot): any {
    const id = route.paramMap.get("id")
    if (id)
      return this.service.get(id)

    return this.service.get({
      mapResponseFn: (res: ApiResponse) => res.results,
      params: {page_size: 300}
    })
    return;
  }
}
