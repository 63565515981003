<div class="columns">
  <div class="column">
    <!-- <div class="card has-background-primary is-hidden-desktop" *ngIf="(isMember$ | async) === false">
      <a href="https://intro.esrtheta.nl/" class="card-header">
        <div class="m-5">
          <p class="title is-4">Lid worden?</p>
          <p class="title is-5">Klik hier om naar de introductie site te gaan!</p>
        </div>
      </a>
    </div> -->
    <esr-announcement
      id="mobile-announcements"
      class="is-hidden-desktop"
    ></esr-announcement>
    <div  *ngIf = "(isLoggedIn$ | async)">
      <div class="tabs is-toggle" style="margin-bottom:-0.1rem; margin-top:-2.5rem;">
        <ul>
          <li [ngClass] = "1 === activeTab ? 'is-active': ''"><a [ngClass] = "1 === activeTab ? 'is-active': 'is-inactive'" routerLink="/news">Nieuws</a></li>
          <li [ngClass] = "2 === activeTab ? 'is-active': ''"><a [ngClass] = "2 === activeTab ? 'is-active': 'is-inactive'" routerLink="/leden/algemeen/activiteiten/">Activiteiten</a></li>
          <li *ngIf = "(isMember$ | async)" [ngClass] = "3 === activeTab ? 'is-active': ''"><a [ngClass] = "3 === activeTab ? 'is-active': 'is-inactive'" routerLink="/leden/algemeen/werkacties/">Werkacties</a></li>
          <li *ngIf = "(isMember$ | async)" [ngClass] = "4 === activeTab ? 'is-active': ''"><a [ngClass] = "4 === activeTab ? 'is-active': 'is-inactive'" routerLink="/leden/algemeen/reserveren/">Reserveren</a></li>
        </ul>
      </div>
    </div>
    <esr-spinner *ngIf="loading; else loaded"></esr-spinner>
    <ng-template #loaded>
      <router-outlet></router-outlet>
    </ng-template>
  </div>

  <div class="column is-one-quarter"  *ngIf="!lustrum">
    <esr-sidebar></esr-sidebar>
  </div>
</div>

