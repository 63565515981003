import { Component } from "@angular/core";
import { RouterLink } from "@angular/router";

/**
 * The `esr-page-not-found` component is meant as a target component for any
 * route that is not explicitly defined. It is a very basic component, simply
 * routing back to the main homepage.
 */
@Component({
    selector: "esr-page-not-found",
    templateUrl: "./page-not-found.component.html",
    standalone: true,
    imports: [RouterLink]
})
export class PageNotFoundComponent {}
