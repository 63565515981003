import { Component, Input } from "@angular/core";
import { RouterLink } from "@angular/router";
import { NgIf } from "@angular/common";
/**
 * ContentComponent is the base component for all content.
 * It implements the bulma card https://bulma.io/documentation/components/card/.
 */
@Component({
    selector: "esr-content",
    templateUrl: "./content.component.html",
    styleUrls: ["./content.component.scss"],
    standalone: true,
    imports: [NgIf, RouterLink]
})
export class ContentComponent {
  /** The text in the header of the component. */
  @Input() header: string = "";
  /** The link for the component's footer. */
  @Input() footer: string = "";
  /** The link for the component's footer. */
  @Input() image: string = "";
}
