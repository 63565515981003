import { Component } from "@angular/core";

import {
  MEMBER_PAGE_SLUGS,
  PUBLIC_PAGE_SLUGS,
} from "src/app/shared-resources/page-slugs";
import { AuthQuery, AuthService } from "../../login-module/auth/state";
import { toggleMobileMenu } from "../navigation.component";
import { filterPageSlugsOnAuthorizationClearance } from "../member-navbar/member-navbar.component";
import { map, Observable, timer } from "rxjs";
import { User } from "src/app/login-module/auth/state/user.model";
import { environment } from "src/environments/environment";
import { NavigationService, NavigationQuery } from "../state";
import { Role } from "src/app/login-module/auth/role.enum";
import { Router, RouterLink } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgFor, NgIf, AsyncPipe } from "@angular/common";

/** The MobileNavbar component */
@Component({
    selector: "esr-mobile-navbar",
    templateUrl: "./mobile-navbar.component.html",
    styleUrls: ["./mobile-navbar.component.scss"],
    standalone: true,
    imports: [NgFor, NgIf, FontAwesomeModule, RouterLink, AsyncPipe]
})
export class MobileNavbarComponent {
  /** Variable of the toggleMobileMenu function so the html template can use it */
  public toggleMobileMenu = toggleMobileMenu;
  /** Boolean observable to toggle the leden navbar */
  public isLoggedIn$ = this.authQuery.isLoggedIn$;
  /** Check if user is admin, used to toggle bestuur link in navbar */
  public isAdmin$ = this.authQuery.isAdmin$;
  /** To keep track what the state of the mobile navbar tab */
  public mobileNavbarTabState: string = "";
  /** Observable of the current User */
  public user$: Observable<User> = this.authQuery.user$;
  /** Base Url for the site, used for the foto */
  public baseUrl = environment.mediaBaseURI;
  
  public lustrum = false;

  public parentPage: string | null = null;
  /** Boolean to keep track if the `activePageSlugs` are a subPage */
  public isSubPage: boolean = false;
  /** The currently displayed page slugs, used in html template */
  public activePageSlugs: any;
  /** The currently displayed page slugs object, used by component */
  private ACTIVE_PAGE_SLUGS: any;
  /** MEMBER_PAGE_SLUGS filterd by the user's roles */
  private ALLOWED_MEMBER_PAGE_SLUGS: any;

  /** Unread spams/frusts for badge notification */
  public unreadChats$ : Observable<boolean> = this.navigationQuery.unreadItems$.pipe(map((items) => items.unreadSpams > 0 || items.unreadFrusts > 0))
  public unreadSpams$ : Observable<number> = this.navigationQuery.unreadItems$.pipe(map((items) => items.unreadSpams))
  public unreadFrusts$ : Observable<number> = this.navigationQuery.unreadItems$.pipe(map((items) => items.unreadFrusts))
  public unreadAlbums$ : Observable<number> = this.navigationQuery.unreadItems$.pipe(map((items) => items.unreadAlbums))
  

  /** @ignore */
  constructor(
    private authService: AuthService,
    private authQuery: AuthQuery,
    private navigationService: NavigationService,
    private navigationQuery: NavigationQuery,
    private router: Router
  ) {
    /** Poll for unread messages every 10 seconds */
    timer(0, 1000 * 10).subscribe(() => {
      if(authQuery.getRoles().includes(Role.Member) || authQuery.getRoles().includes(Role.SerMember)){
        this.navigationService.getUnreadMessages();
        this.navigationQuery.unreadItems$.subscribe((data) => {
          // this.unreadSpams = data.unreadSpams;
          // this.unreadFrusts = data.unreadFrusts;
          // this.unreadAlbums = data.unreadAlbums;
        })
      }
    })

    router.events.subscribe(val => {
      if (this.router.url.includes("lustrum")) {
        this.lustrum = true;
      } else {
        this.lustrum = false;
      }
    })

    this.authQuery.isLoggedIn$.subscribe(
      (isLoggedIn) =>{
        this.parentPage = null;
        this.mobileNavbarTabState = isLoggedIn ? "member" : "public";
      }
        
    );

    this.authQuery.roles$.subscribe((roles) => {
      if (this.authQuery.isLoggedIn() && roles) {
        const filteredPageSlugs: any = filterPageSlugsOnAuthorizationClearance(
          roles,
          MEMBER_PAGE_SLUGS
        );
        this.ALLOWED_MEMBER_PAGE_SLUGS = {};
        // Remove main-menus without sub-menu items
        Object.keys(filteredPageSlugs).forEach((pageSlug) => {
          if (Object.entries(filteredPageSlugs[pageSlug]).length > 0) {
            this.ALLOWED_MEMBER_PAGE_SLUGS = {
              ...this.ALLOWED_MEMBER_PAGE_SLUGS,
              [pageSlug]: filteredPageSlugs[pageSlug],
            };
          }
        });

        this.setActivePageSlugs(this.ALLOWED_MEMBER_PAGE_SLUGS, false);
      } else {
        this.setActivePageSlugs(PUBLIC_PAGE_SLUGS, false);
      }
    });
  }

  /** Sets the `activePageSlugs` for the html template
   *  Sets the `ACTIVE_PAGE_SLUGS` for the component
   *  Sets the `isSubPage`
   */
  setActivePageSlugs(pageSlugs: {}, isSubPage: boolean) {
    this.isSubPage = isSubPage;
    this.ACTIVE_PAGE_SLUGS = pageSlugs;
    this.activePageSlugs = isSubPage
      ? Object.keys(pageSlugs)
      : Object.keys(pageSlugs);
  }

  /** Hanldes the back button on subpages to set the `activePageSlugs` */
  handleBackButton() {
    this.parentPage = null;
    if (this.authQuery.isLoggedIn()) {
      this.mobileNavbarTabState === "public"
        ? this.setActivePageSlugs(PUBLIC_PAGE_SLUGS, false)
        : this.setActivePageSlugs(this.ALLOWED_MEMBER_PAGE_SLUGS, false);
    } else {
      this.setActivePageSlugs(PUBLIC_PAGE_SLUGS, false);
    }
  }

  /**
   * Given the slug type it sets:
   * 1. the active state of the tab in the mobile menu
   * 2. sets the activePageSlugs to either public or member
   * 3. sets the mobileNavbarTabState
   */
  setMobileNavbarTabState(tabState: string) {
    this.parentPage = null
    switch (tabState) {
      case "public":
        let publicTarget = document.getElementById("public-mobile-pages")
        if (publicTarget) publicTarget.classList.add("is-active");

        publicTarget = document.getElementById("member-mobile-pages")
        if (publicTarget) publicTarget.classList.remove("is-active");

        this.mobileNavbarTabState = tabState;
        this.setActivePageSlugs(PUBLIC_PAGE_SLUGS, false);
        break;
      case "member":
        let memberTarget = document.getElementById("member-mobile-pages")
        if (memberTarget) memberTarget.classList.add("is-active");

        memberTarget = document.getElementById("public-mobile-pages")
        if (memberTarget) memberTarget.classList.remove("is-active");

        this.mobileNavbarTabState = tabState;
        this.setActivePageSlugs(this.ALLOWED_MEMBER_PAGE_SLUGS, false);
    }
  }

  /** Given a page-slug return whether it has sub pages */
  hasSubPages = (page: string) =>
    typeof this.ACTIVE_PAGE_SLUGS[page] === "object" &&
    Object.keys(this.ACTIVE_PAGE_SLUGS[page]).length > 1;

  /** Given a page-slug sets the `ACTIVE_PAGE_SLUGS` */
  setSubPages = (page: string) => {
    this.parentPage = page;
    this.setActivePageSlugs(this.ACTIVE_PAGE_SLUGS[page], true);
  };

  /** Given a page-slug returns the human readable format */
  getPageName = (page: string) =>
    this.ACTIVE_PAGE_SLUGS[page][page]
      ? this.ACTIVE_PAGE_SLUGS[page][page]
      : this.ACTIVE_PAGE_SLUGS[page];

  /** Call the authService to log out */
  logout() {
    this.setMobileNavbarTabState("public");
    this.toggleMobileMenu();
    this.authService.logout();
  }


}
