import { User } from "src/app/login-module/auth/state/user.model";
import { Comment } from "./comment.model";

/** News model */
export interface News {
  /** News Id. */
  id: number;
  /** Author of the news */
  author: Partial<User>;
  /** One HTML string with the news content. */
  content: string;
  /** String with name of member on which's behalf news was posted */
  on_behalf: string;
  /** URL location of photo on metis server. */
  photo_url: string;
  /** ISOString of date news was posted */
  pub_date: string;
  /** Subtitle */
  subtitle: string;
  /** Title of the news item. */
  title: string;
  /** `boardmessage`, used to toggle boardmessage flag */
  boardmessage: boolean;
  /** `members_only`, used to filter who can see the news */
  members_only: boolean;
  /** `ser_item`, used to filter who can see the news */
  ser_item: boolean;
  /** `on_top`, used to pin the news item to the top */
  on_top: boolean;
  /** `date_edited`, ISOString of date news was edited */
  date_edited: string;
  /** Comments */
  comments: Comment[];

  attachment_file_type: string;

  attachment_url: string;
}
