import { Injectable } from "@angular/core";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { Announcement } from "./announcement.model";

export interface AnnouncementState extends EntityState<Announcement> {}

@StoreConfig({ name: "announcement" })
@Injectable({ providedIn: "root" })
export class AnnouncementStore extends EntityStore<AnnouncementState> {
  constructor() {
    super();
  }
}
