<div class="is-hidden-desktop">
  <div id="mobile-main-pages">
    <ng-container *ngFor="let page of activePageSlugs; let first=first">

      <!-- Navbar item has sub pages -->
      <div *ngIf="hasSubPages(page); else noSubPage" class="navbar-item">
        <a id="{{ page }}" class="navbar-link is-arrowless" (click)="setSubPages(page)">
          {{ getPageName(page) }}
          <fa-icon style="float: right" [icon]="['fas','arrow-right']"></fa-icon>
        </a>
      </div>

      <!-- Navbar item has no sub pages -->
      <ng-template #noSubPage>
        <div class="navbar-item">
          <a *ngIf="mobileNavbarTabState === 'public'; else memberRouting" id="{{ parentPage }}-{{ page}}"
            class="navbar-link is-arrowless" (click)="toggleMobileMenu()" routerLink="/publiek/{{!first && isSubPage ? parentPage + '/' : '' }}{{ page }}">
            <ng-container *ngIf="unreadAlbums$ | async as unreadAlbums">
              <ng-container *ngIf="!lustrum">
                <span *ngIf="getPageName(page) === 'Fotoalbums' && unreadAlbums > 0" title="Badge"
                  class="badge is-right is-primary"
                  style='box-shadow: none;transform: translate(-20%, -50%);color: black;'>{{unreadAlbums}} ongezien
                </span>
              </ng-container>
              <ng-container *ngIf="lustrum">
                <span *ngIf="getPageName(page) === 'Fotoalbums' && unreadAlbums > 0" title="Badge"
                  class="badge is-right"
                  style='box-shadow: none;transform: translate(-20%, -50%);color:#fff4e2; background:#9b1b1f;'>{{unreadAlbums}} ongezien
                </span>
              </ng-container>
            </ng-container>
            {{ getPageName(page) }}
          </a>
          <ng-template #memberRouting>
            <a id="{{ parentPage }}-{{ page}}" class="navbar-link is-arrowless" (click)="toggleMobileMenu()"
              routerLink="/leden/{{!first && isSubPage ? parentPage + '/' : '' }}{{ page }}">
              <ng-container *ngIf="unreadSpams$ | async as unreadSpams">
                <ng-container *ngIf="!lustrum">
                  <span *ngIf="getPageName(page) === 'Spammen' && unreadSpams > 0" title="Badge"
                    class="badge is-right is-primary"
                    style='box-shadow: none;transform: translate(-20%, -50%);'>{{ unreadSpams }} ongelezen
                  </span>
                </ng-container>
                <ng-container *ngIf="lustrum">
                  <span *ngIf="getPageName(page) === 'Spammen' && unreadSpams > 0" title="Badge"
                    class="badge is-right"
                    style='box-shadow: none;transform: translate(-20%, -50%); color:#fff4e2; background:#9b1b1f;'>{{ unreadSpams }} ongelezen
                  </span>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="unreadFrusts$ | async as unreadFrusts">
                <ng-container *ngIf="!lustrum">
                  <span *ngIf="getPageName(page) === 'Frusten' && unreadFrusts > 0" title="Badge"
                    class="badge is-right is-primary"
                    style='box-shadow: none;transform: translate(-20%, -50%);'>{{ unreadFrusts }} ongelezen
                  </span>
                </ng-container>
                <ng-container *ngIf="lustrum">
                  <span *ngIf="getPageName(page) === 'Frusten' && unreadFrusts > 0" title="Badge"
                    class="badge is-right"
                    style='box-shadow: none;transform: translate(-20%, -50%); color:#fff4e2; background:#9b1b1f;'>{{ unreadFrusts }} ongelezen
                  </span>
                </ng-container>
              </ng-container>
              {{ getPageName(page) }}
            </a>
          </ng-template>
        </div>
      </ng-template>
    </ng-container>

    <!-- Back Button on Sub Pages -->
    <div *ngIf="isSubPage; else notSubPage">
      <div class="navbar-item">
        <a id="mobile-menu-back" class="navbar-item" (click)="handleBackButton()">
          <fa-icon [icon]="['fas','arrow-left']"></fa-icon> Terug
        </a>
      </div>
    </div>

    <!-- Buttons not included in PageSlugs -->
    <ng-template #notSubPage>
      <!-- Public not included in PageSlugs -->
      <ng-container *ngIf="mobileNavbarTabState === 'public'; else memberTab">
        <div class="navbar-item">
          <a class="navbar-link is-arrowless" (click)="toggleMobileMenu()" routerLink="/publiek/lustrum/">Lustrum</a>
        </div>
        <div class="navbar-item" *ngIf="isLoggedIn$ | async">
          <a class="navbar-link is-arrowless" (click)="toggleMobileMenu()" routerLink="/publiek/lustrum/pakketten/">Lustrumpakketten</a>
        </div>
        <div class="navbar-item">
          <a class="navbar-link is-arrowless" href="https://zakelijk.esrtheta.nl/">Samenwerken</a>
        </div>
        <div class="navbar-item">
          <a class="navbar-link is-arrowless" (click)="toggleMobileMenu()" routerLink="/publiek/zakelijk/vacatures/">Vacatures</a>
        </div>
        <div class="navbar-item">
          <a class="navbar-link is-arrowless has-text-info"
          href="mailto:incoming+esrtheta-web3-5-frontend-12458795-issue-@incoming.gitlab.com?body=alsjeblief aangeven wat er mis ging en wat jij had verwacht dat zou gebeuren. Screenshots zijn een groote hulp! Dankjewel voor de feedback!">Feedback</a>
        </div>
      </ng-container>

      <!-- Member items not included in PageSlugs: vacatures, profile link and beheer if admin  -->
      <ng-template #memberTab>
        <div class="navbar-item">
          <a class="navbar-link is-arrowless" (click)="toggleMobileMenu()" routerLink="/publiek/zakelijk/vacatures/">Vacatures</a>
        </div>
        <div class="navbar-item">
          <a class="navbar-link is-arrowless" *ngIf="user$ | async as user" routerLink="/leden/profiel/{{user.id}}"
            (click)="toggleMobileMenu()">
              <div class="level is-mobile">
                <div class="level-left">
                  <div class="level-item">
                    <span class="mr-3">Mijn Profiel</span>
                  </div>
                </div>
                <div class="level-right">
                  <div class="level-item">
                    <figure class="image is-32x32" *ngIf="user$ | async as user">
                      <img src="{{baseUrl}}{{ user.photo_url }}" alt="Image not found" class="is-rounded"
                        onerror="this.onerror=null; this.src='./assets/blank-profile-picture.png';"
                        style="object-fit: cover; max-height: 100%; height: 100%;" />
                    </figure>
                  </div>
                </div>
              </div>
          </a>
        </div>
        <div *ngIf="isAdmin$ | async" class="navbar-item">
          <a id="mobile-beheer-menu" class="navbar-link is-arrowless" routerLink="/leden/beheer" (click)="toggleMobileMenu()">
            Beheer
          </a>
        </div>
      </ng-template>
    </ng-template>

    <!-- Bottom Tab Menu When logged in -->
    <div class="tabs is-centered">
      <ul *ngIf="isLoggedIn$ | async; else elseBlock">
        <li><a (click)="logout()">Logout</a></li>
        <li id="public-mobile-pages" (click)="setMobileNavbarTabState('public')">
          <ng-container *ngIf="unreadAlbums$ | async as unreadAlbums">
            <ng-container *ngIf="!lustrum">
              <span *ngIf="unreadAlbums > 0" title="Badge" class="badge is-top-right is-primary"
                style='box-shadow: none;transform: translate(-30%, 20%);color: black;'></span>
            </ng-container>
            <ng-container *ngIf="lustrum">
              <span *ngIf="unreadAlbums > 0" title="Badge" class="badge is-top-right is-primary"
                style='box-shadow: none;transform: translate(-30%, 20%);color:#fff4e2;background-color:#9b1b1f;'></span>
            </ng-container>
          </ng-container>
          <a>Publiek</a>
        </li>
        <li id="member-mobile-pages" class="is-active" (click)="setMobileNavbarTabState('member')">
          <ng-container *ngIf="unreadChats$ | async as unreadChats">
            <ng-container *ngIf="lustrum">
              <span *ngIf="unreadChats" title="Badge" class="badge is-top-right"
                style='box-shadow: none;transform: translate(-10%, 10%);color:#fff4e2;background-color:#9b1b1f;'></span>
            </ng-container>
            <ng-container *ngIf="!lustrum">
              <span *ngIf="unreadChats" title="Badge" class="badge is-top-right is-primary"
                style='box-shadow: none;transform: translate(-10%, 10%);color:black;'></span>
            </ng-container>
          </ng-container>
          <a>Leden</a>
        </li>
      </ul>
    </div>
    <ng-template #elseBlock>
      <a class="card-footer-item" routerLink="/login" (click)="toggleMobileMenu()">
        Login
      </a>
    </ng-template>
  </div>
</div>