import { Role } from "./role.enum";

/** Hierarchy of roles. This way when we specify a expectedRole for a route,
 * we don't have to provide all Roles that are included.
 */
export const ROLES_HIERARCHY = {
  aspirant: [
    Role.Aspirant
  ],
  sermember: [
    Role.SerMember
  ],
  member: [
    Role.Bestuur,
    Role.Photographer,
    Role.Member,
    Role.SerBestuur,
    Role.Admin,
  ],
  medewerker: [
    Role.Medewerker,
  ],
  erelid: [
    Role.Erelid_LVV
  ],
  beheer: [Role.Bestuur, Role.Photographer, Role.Admin, Role.Acquisicie, Role.Fancie],
  bestuur: [Role.Bestuur, Role.Admin],
  "ser-bestuur": [Role.SerBestuur, Role.Admin],
  admin: [Role.Admin],
};
