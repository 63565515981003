import { Injectable } from "@angular/core";
import { QueryEntity } from "@datorama/akita";
import { RouterQuery } from "@datorama/akita-ng-router-store";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { Link } from "./link";
import { LinkStore as LinkStore, LinkState } from "./store";

/**
 * A Query is a class offering functionality responsible for querying the store.
 * You can think of the query as being similar to database queries.
 * Its constructor function receives as parameters its own store and possibly other query classes.
 */
@Injectable({ providedIn: "root" })
export class LinkQuery extends QueryEntity<LinkState> {
  /** Observable of all sponsors */
  public links$: Observable<Link[]> = this.selectAll({sortBy: 'position'});

  public activeLinks$: Observable<Link[]> = this.selectAll({sortBy: 'position', filterBy: (link) =>{ return link.is_active}});
  /**
  * Observable of item selected by route id parameter.
  */
  public link$ = this.routerQuery
  .selectParams("id")
  .pipe(
    map((i) => {
      if (i) return i;
    })
  )
  .pipe(switchMap((i) => this.selectEntity(i)));
    
  /** @ignore */
  constructor(protected store: LinkStore, public routerQuery: RouterQuery) {
    super(store);
  }

    /** Returns a boolean whether there is a next page to fetch. */
    getHasMore() {
      return this.getValue().hasMore;
    }
  
    /** Returns the next page number to fetch. */
    getPage() {
      return this.getValue().nextPage;
    }
}
